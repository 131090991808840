import React, { Fragment, useEffect, useRef, useState } from "react";
import Icon from "@opidcore/components/Icon";
import { useData } from "@opidcore/hooks/WTF";
import { Bound, Button, InputMultiCheckbox, InputText, InputToggleSwitch } from "@opidcore/components"; 
import ThingLink from "../components/ThingLink";
import moment from "moment";
import _ from "lodash";
//import { Field } from "../components/ViewLayouts"; 

/*

  ex: <Notes  model={inv} 
             entities={[{__type: "Customer", id: 123}, {__type: "Site", id: 110}, {__type: "Site", id: 111}]} 
             types={["APInvoice", "ARInvoice", "Customer", "Site"]}
      />
 
  "model" is the current model being viewed, it must have a __type and an id.
 
  "entities" should be an array of { __type: "EntitiyType", id: entityID }. These are relevant entities which may have notes to display on this model.
 
  "types" should be an array of Strings for the types of Note that should be displayed for the "entities"
    These are noteType lookups in db. parameter1 is used to track which entity type they are for.
*/
export default function Notes(props) {
  const model = props.model;

  if (model.__type) {
    const relatedTable = model.__type;
    const relatedId = model.id;
    const [data, dataSet] = useData("note;" + relatedTable + "-" + relatedId, {
      method: "fetchNotes",
      relatedEntities: props.entities,
      noteTypes: props.types,
      relatedTable: relatedTable,
      relatedId: relatedId,
    });
    const [count, setCount] = useState(0);
    const [stickyNotes, setStickyNotes] = useState([]);

    useEffect(() => {
      let tempCount = 0;
      _.forEach(data, (item) => {
        if (item.historical == false) {
          tempCount++;
        }
      });
      setCount(tempCount);
    }, [data]);

    useEffect(() => {
      dataSet.callback((notes) => {
        setStickyNotes(
          notes.filter((note) => {
            if (note.sticky == true && note.historical != true) {
              return note;
            }
          })
        );
      });
      //window.cesar = dataSet;
    }, []);

    const openNotes = (dataSet) => {
      // @ts-ignore
      APP.instance.createModal(<NotesModal dataSet={dataSet} />, { modal_name: "Notes" });
    };

    return (
      <Fragment>
        <StickyNotes notes={stickyNotes} />
        <div className="fa-2x" title={"Open Notes for " + relatedTable + " - " + (model.name ? model.name : model.friendlyId ? model.friendlyId : model.id)}>
          <span className="fa-layers fa-fw">
            <Icon fType="fal" icon="sticky-note" size="2x" onClick={() => openNotes(dataSet)} type="span" className="clickable-icon" />
            {count ? (
              <span className="fa-layers-counter" style={{ background: "Tomato" }}>
                <strong>{count}</strong>
              </span>
            ) : null}
          </span>
        </div>
      </Fragment>
    );
  } else return null;
}

export function StickyNotes(props) {
  const stickyNotes = props.notes;

  const getPreviewBody = (body) => {
    if (body.length > 20) {
      return body.substring(0, 20) + "...";
    } else {
      return body;
    }
  };

  return (
    <div className="sticky-note-container">
      {stickyNotes.map((note) => {
        return (
          <div className="sticky-note" key={note.id}>
            {getPreviewBody(note.body)}
            <span className="sticky-note-tooltiptext">
              {note.body}
              <div className="flexBreak" />
              <div>
                <NiceStaffAvatar staff={note.staff} size={32} />
                <div className="time-tooltip">
                  <span title={moment(note.created).format("MMM Do YYYY hh:mm:ss")}>{moment(note.created).fromNow()}</span>
                </div>
              </div>
            </span>
          </div>
        );
      })}
    </div>
  );
}

export function NotesModal(props) {
  const dataSet = props.dataSet;
  const [data, setData] = useState(dataSet.filter());
  const showHistorical = useRef({ showHistorical: false });
  const [count, setCount] = useState({ nonHistoricalCount: 0, historicalCount: 0 });

  const [allNoteTypes, setAllNoteTypes] = useState([]);

  useEffect(() => {
    dataSet.callback(setData);
    dataSet.callback(updateTotals);
    dataSet.filter((r) => r.historical == false, true);

    APP.central.Note.fetchAvailableNoteTypes().then((fetchAvailableNoteTypes) => {
      setAllNoteTypes(fetchAvailableNoteTypes.result);
    });
  }, []);

  const updateTotals = () => {
    let nonHistoricalCount = 0;
    let historicalCount = 0;
    _.forEach(dataSet.origData, (item) => {
      if (item.historical == false) {
        nonHistoricalCount++;
      }
      if (item.historical == true) {
        historicalCount++;
      }
    });
    setCount({ nonHistoricalCount: nonHistoricalCount, historicalCount: historicalCount });
  };

  const newNote = () => {
    // @ts-ignore
    APP.central.Staff.fetch(USER.userId).then((r) => {
      const userStaff = r.result;
      const newNote = {
        body: "",
        created: moment(),
        id: 0,
        noteType: "",
        priority: 0,
        relatedId: dataSet.queryArgs.options.relatedId,
        relatedTable: dataSet.queryArgs.options.relatedTable,
        staff: userStaff,
        sticky: false,
        historical: false,
        __type: "Note",
      };

      // @ts-ignore
      APP.central.Note.saveChanges(newNote.id, newNote).then((r) => {
        dataSet.addOrUpdate(r.result);
      });
    });
  };

  const doHistoricalToggle = () => {
    dataSet.reset();

    if (!showHistorical.current.showHistorical) {
      dataSet.filter((r) => r.historical == false, true);
    }
    if (showHistorical.current.showHistorical) {
      dataSet.filter((r) => r.historical == true, true);
    }
  };

  const seeHistoricalNotes = () => {
    showHistorical.current.showHistorical = true;
    doHistoricalToggle();
  };

  const seeNonHistoricalNotes = () => {
    showHistorical.current.showHistorical = false;
    doHistoricalToggle();
  };

  return (
    <div className="notes-modal">
      <div className="notes-modal-header">
        <h3 className="notes-header" onClick={seeNonHistoricalNotes}>
          {count.nonHistoricalCount} Notes
        </h3>
        <h3> | </h3>
        <h3 className="notes-header" onClick={seeHistoricalNotes}>
          {count.historicalCount} Historical
        </h3>
      </div>

      {showHistorical.current.showHistorical ? null : (
        <div className="action_button new-note" onClick={newNote} title={"New Note"}>
          <Icon icon="plus" size="2x" />
        </div>
      )}

      {data
        .sort((a, b) => {
          if (a.body == "") {
            return -1;
          }
          if (b.body == "") {
            return 1;
          }
          let prio = b.priority - a.priority;
          if (prio == 0) {
            let timediff = moment(b.created).diff(moment(a.created));
            return timediff;
          } else {
            return prio;
          }
        })
        .map((d) => {
          return (
            <Fragment>
              <Note
                key={d.id}
                data={d}
                unlocked={d.unlocked}
                refresh={(note) => {
                  if (note == null) {
                    dataSet.fetch();
                  } else {
                    dataSet.addOrUpdate(note);
                  }
                  doHistoricalToggle();
                }}
                allNoteTypes={allNoteTypes}
              />
              <hr />
            </Fragment>
          );
        })}
    </div>
  );
}

export function Note(props) {
  const data = props.data;
  const [unlocked, setUnlocked] = useState(data.body == "" ? true : false);
  const allNoteTypes = props.allNoteTypes ? props.allNoteTypes : [];

  let showNoteType = false;
  // set showNoteType to true if data.relatedTable matches any of allNoteTypes[x].parameter1
  for (let i = 0; i < allNoteTypes.length; i++) {
    if (data.relatedTable == allNoteTypes[i].parameter1) {
      showNoteType = true;
    }
  }

  const noteTypes = data.noteType ? _.split(data.noteType, "|") : [];

  const editNote = () => {
    setUnlocked(!unlocked);
  };

  const doSave = () => {
    delete data.created;
    // @ts-ignore
    APP.central.Note.saveChanges(data.id, data).then((r) => {
      setUnlocked(false);
      props.refresh(r.result);
    });
  };

  const doDelete = () => {
    delete data.created;
    // @ts-ignore
    APP.central.Note.delete(data.id).then((r) => {
      props.refresh(null);
    });
  };

  const unlockedStuff = (
    <Fragment>
      <Bound key={data.id} to={data}>
        <div style={{ width: "100px", marginLeft: "10px" }}>
          <InputToggleSwitch
            field="historical"
            name={(status) => {
              return status == undefined || status == false ? "Not Historical" : "Historical";
            }}
          />
        </div>
        <div style={{ width: "100px" }}>
          <InputToggleSwitch
            field="sticky"
            name={(status) => {
              return status == undefined || status == false ? "Not Sticky" : "Sticky";
            }}
          />
        </div>

        {showNoteType ? (
          <div className="field">
            <label>Display On Related:</label>
            <div className="Note-InputMultiCheckbox">
              <InputMultiCheckbox field="noteType" optionsCollection={allNoteTypes} optionKey={"parameter1"} startSelected={noteTypes} storeAs="string">
                {(row) => <div className="Note-InputMultiCheckbox-Item">{row.label}</div>}
              </InputMultiCheckbox>
            </div>
          </div>
        ) : null}

        <div style={{ width: "60px", marginRight: "10px" }}>
          <InputText inputType="number" field="priority" name="Priority:" />
        </div>
        <Button onClick={doSave}>Save</Button>
        {/* <Button onClick={doDelete}>Delete</Button> */}
      </Bound>
    </Fragment>
  );

  return (
    <div className="note-container">
      <Bound key={data.id} to={data}>
        {unlocked ? (
          <InputText inputType="textarea" field="body" style={{ flex: "auto" }} maxlength="500" />
        ) : (
          <p className="note-body" onDoubleClick={editNote}>
            {data.body}
          </p>
        )}
      </Bound>

      <div className="avatar-container">
        <NiceStaffAvatar staff={data.staff} />
        <br />
        <div className="time-tooltip">
          {moment(data.created).fromNow()}
          <span className="time-tooltiptext">{moment(data.created).format("MMM Do YYYY hh:mm:ss")}</span>
        </div>
        <br />
        <div className="attached-to">
          <span className="attached-to-thing">
            Attached To
            <br />
            <ThingLink relatedType={data.relatedTable} relatedId={data.relatedId}></ThingLink>
          </span>
        </div>
      </div>  

      <div className="flexBreak" />
      <div className="noteBottomHalf">
        <div className="action_button" onClick={editNote} title={"Edit Note"}>
          <Icon icon="edit" size="1x" />
        </div>
        {unlocked ? unlockedStuff : null}
      </div>
    </div>
  );
}

export function NiceStaffAvatar(props) {
  const staff = props.staff;
  let avatarStyle = {};

  if (props.size) {
    avatarStyle = { width: props.size + "px", borderRadius: props.size / 2 + "px" };
  }

  if (staff == null) {
    return (
      <div className="avatar">
        <span className="name">{"?"}</span>
      </div>
    );
  }

  return (
    <div className="avatar">
      <img src={"/" + staff.avatar} style={avatarStyle} />
      <span className="name">{(staff.firstName ? staff.firstName : "-") + " " + (staff.lastName ? staff.lastName : "-")}</span>
    </div>
  );
}
