import React, { useEffect, useState, useContext, useRef, Fragment} from 'react';
//import { Link } from 'react-router-dom';
import { useData, useNavBar } from '@opidcore/hooks/WTF';
import { BoundCommit, Bound, InputText, DatePicker, InputSelect,  UnlockToEdit,  Icon, FlexBreak, FlexRow, GridItem, InputDecimal, LookupInputSelect, Button, ActionBar, HoverElement, Expandable, InputToggleSwitch } from '@opidcore/components';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import * as _ from 'lodash';
//import { Data } from '@opidcore/Data';

import { XeroLink, StaffSelect, ClientSiteServiceSelector, SimpleMonthPicker } from '../../components';
import LinkButton from '../../components/LinkButton'
//import DisplayThing, { BoundDisplayThing } from '../../components/DisplayThing';
import Grid from '@opidcore/components/Grid';
import { DataContext } from '@opidcore/components/Bound';
import Util from '@opidcore/Util';
import { NiceNumber, NiceCurrency, NicePercent } from '../Nice.jsx';
import moment from 'moment';
import Notes, { NiceStaffAvatar } from '../Notes';
import { ToolbarContext } from '@opidcore/components/SaveToolbar';
import { withXero } from '../../components/XeroLink';
// import { RouteWatcher } from '@opidcore/components/OpidApplication';
// import { difference, update } from 'lodash';
import Tasks from '../Tasks';
import ChangeLogs from '../ChangeLog/View';
import { EditVendorActivity } from '../Admin/VendorActivities';
import { EditActivity } from '../Admin/ActivitiesListing';
//import { Prospect } from '../../Modules';
import { DatePickerWithPeriodToUpdater } from '../ARInvoice/EditARInvoice';
import File from '../../components/File';
import SimplePDFPreview from '../../components/SimplePDFPreview';
import { CopyAPThing, DuplicateAPDetails } from '../AbominableSnowman/PayablesProcess';
import { Link } from 'react-router-dom';

function StyleStyle(props) {	
	return <style dangerouslySetInnerHTML={{ __html: props.rules }} />;
}

const setTax = (a, b, c, d) => {
	if (false || true) {
		if(a.list){
			const tax = a.list.find((r) => r.id == a.value);
			const newTaxObj = {};
			if (tax) {
				newTaxObj[tax.code] = 0;
				if (a.context) {
					a.context.magicalSet("taxes", newTaxObj);  
				} 
			}
		}		
	}
};

const PaymentAdjustment = () => {
	const boundMagic = useContext(DataContext);
	const [paymentAdjustment, setPaymentAdjustment] = useState(0);

	useEffect(() => {
		const parentMagic = boundMagic.getParentMagic();

		parentMagic.magicalState("paymentAdjustment", setPaymentAdjustment);
	}, [boundMagic]);

	let className = "";

	if (paymentAdjustment < 0) {
		//className = "short-paid";
	}

	return <div key={"payment_adjustment_" + boundMagic.magicalGet("id")} className={className}><NiceCurrency>{paymentAdjustment}</NiceCurrency></div>;
}

const calculateFinalTotal = (initialValue, paymentAdjustment, applyPaymentAdjustment, tax, applyTax) => {
	let finalTotal = initialValue;

	if (applyPaymentAdjustment) {
		finalTotal += paymentAdjustment;
	}

	if (applyTax) {
		if (tax != undefined) {
			finalTotal = finalTotal * (1 + tax / 100);
		}
	}

	return finalTotal;
}

export function TotalsFormulaResult({ value, paymentAdjustment, taxRate }) {
	return <div>
		<NiceCurrency>
			{calculateFinalTotal(value, paymentAdjustment, true, taxRate, true)}
		</NiceCurrency>
	</div>;
}

export function TotalsFormula({ field, applyTax = false, applyPaymentAdjustment = false }) {
	const bound = useContext(DataContext);
	const [value, setValue] = useState(null);

	useEffect(() => {
		bound.magicalState(field, setValue, { defaultValue: 0 });
	}, [bound]);

	return <TotalsFormulaResult value={value} paymentAdjustment={applyPaymentAdjustment ? bound.getParentMagic().to.paymentAdjustment : 0} taxRate={bound.to.taxId != undefined && applyTax ? bound.to.taxId.rate : 0} />;
}

function IssueChecker({ data = undefined }) {
	const boundMagic = useContext(DataContext);
	const [options, setOptions] = useState([]);
	const [errorsAndInconsistencies, setErrors] = useState([]);
	const [numIssuesDisplay, setNumIssuesDisplay] = useState(<div></div>);

	const applyShortPay = (e) => {

		// const lineIdx = _.findIndex(boundMagic.magicalGet("lines"), (l)=>l.items && l.items[0] && l.items[0].id == data.id);
		// const line = boundMagic.getBound("lines",lineIdx)

		if (boundMagic.magicalGet("__type") == 'LineItem') {
			const issue = boundMagic.magicalGet("errorsAndInconsistencies", [])[0];
			let contractedAmt = null;
			let chargedAmt = null;
			let note = "Pricing incorrect according to contract";
			if (issue && issue.relatedItem && issue.relatedItem.extendedPrice) {
				contractedAmt = issue.relatedItem.extendedPrice;
				chargedAmt = contractedAmt + issue.difference;
				note = "Price does not match contracted unit price. Expected unit price was " + Util.currency(contractedAmt) + " but charged " + Util.currency(chargedAmt);
			}

			if (issue && issue.type == "surcharge_incorrect") {
				note = "Surcharge was charged at " + (Util.round(boundMagic.magicalGet('unitPrice') * 100, 4) + '%') + " instead of " + (Util.round(issue.relatedItem.unitPrice * 100, 4) + '%');
			}

			boundMagic
				.getParentMagic()
				.magicalSet(
					"paymentAdjustmentNotes",
					note
				);
			boundMagic.getParentMagic().magicalSet("paymentAdjustment", -1 * issue.difference);
		} else {
			APP.alert("Please apply the short pay while editing the AP");
		}
	}

	useEffect(() => {
		boundMagic.magicalState("errorsAndInconsistencies", setErrors);
	}, [boundMagic]);

	useEffect(() => {
		let color = "Tomato";

		if (data && data._ourMagic && data._ourMagic.parentMagic && data._ourMagic.parentMagic.to.paymentAdjustmentNotes) {
			color = "gray";
		} else if (boundMagic && boundMagic.getParentMagic() && boundMagic.getParentMagic().to && boundMagic.getParentMagic().to.paymentAdjustmentNotes) {
			color = "gray";
		} else if (data && data.lineItemTypeRelatedId) {
			if (APP.registeredBoundMagics.AP && APP.registeredBoundMagics.AP.to && APP.registeredBoundMagics.AP.to.lines) {
				const line = _.find(APP.registeredBoundMagics.AP.to.lines, ['id', data.lineItemTypeRelatedId]);
				if (line && line.paymentAdjustmentNotes) {
					color = "gray";
				}
			}

		}

		setNumIssuesDisplay(
			<div className="numberCircle" style={{ background: color }}>
				<strong>{options.length}</strong>
			</div>
		);
	}, [boundMagic, options, data]);

	useEffect(() => {
		let relatedItems = boundMagic.magicalGet("relatedItems");
		let itemTotal = boundMagic.magicalGet("total");
		let errors = errorsAndInconsistencies;

		if (data != undefined) {
			errors = data.errorsAndInconsistencies;
			relatedItems = data.relatedItems;
			itemTotal = data.total;
		}

		if (itemTotal == undefined || itemTotal == "") {
			itemTotal = 0;
		}

		let relatedTypes = [];
		let total = 0;
		let options = [];

		if (relatedItems != null && relatedItems.length > 0) {
			_.forEach(relatedItems, (item) => {
				if (item.lineItemType != "ar") {
					relatedTypes.push(item.lineItemType);
					total += (item.extendedPrice ? item.extendedPrice : 0);
				}
			});

			if (itemTotal != total || relatedTypes.length <= 0) {
				options.push({ name: "Balance differs by $" + (Util.roundNice(Math.abs(itemTotal - total), 2)) + ". Found items for: " + relatedTypes.join(",") });
			} else {
				options.push({ name: relatedTypes.join(",") });
			}
		}

		_.forEach(errors, (e) => {
			if (e.showApplyButton) {
				options.push({ name: "Apply Short Pay", title: e.status, action: applyShortPay });
			} else {
				options.push({ name: e.status, action: null });
			}
		});

		setOptions(options);


	}, [errorsAndInconsistencies, data]);

	let actions = _.map(options, (o) => { return <div key={o.name}>{o.action == null ? o.name : <> {o.title} <button onClick={o.action}>{o.name}</button></>}</div> });

	if (actions.length == 0) {
		return <div className="fixes"><span className="hover-text">-</span></div>
	}


	//<HoverElement content={actions.length + ""}>

	return <div className="fixes">
		<HoverElement content={numIssuesDisplay}>
			<div className="issues">
				{actions}
			</div>
		</HoverElement>
	</div>;
}

function LineEdit(props) {
	const [boundMagic, setBoundMagic] = useState({ magicalGet: undefined, magicalSet: undefined });

	const [services, setServices] = useState([]);
	const [sites, setSites] = useState([]);

	useEffect(() => {
		updateSites();
		updateServices();
	}, [boundMagic])

	const _setBoundMagic = (magic) => {
		if (!(magic.magicalGet("customer").typeof === "object")) {
			magic.to.customer = props.customer
		}
		setBoundMagic(magic);
	}

	const updateSites = () => {
		if (boundMagic.magicalGet != null) {
			const customer = boundMagic.magicalGet("customer", null);

			if (customer != null) {
				APP.central.Site.fetchSitesForCustomer(customer.id).then((r) => {
					setSites(r.result);
				});
			}
		}
	}

	const updateServices = () => {
		if (boundMagic.magicalGet != null) {
			const site = boundMagic.magicalGet("site", null);
			if (typeof site == "object" && site != null && site.services != null && site.services.length > 0) {
				setServices(site.services);
			} else {
				const customer = boundMagic.magicalGet("customer", null);
				let siteId = site;
				if (siteId != null && typeof siteId == "object") {
					siteId = siteId.id;
				}

				if (customer != null) {
					APP.central.Service.fetchServicesForCustomer(customer.id, siteId).then((r) => {
						setServices(r.result);
					});
				}
			}
		}
	}

	const openClientSiteSelector = () => {
		const setter = (selectedService, selectedSite, selectedClient) => {
			APP.central.Service.fetch(selectedService.id).then((serviceR) => {
				APP.central.Customer.fetch(selectedClient.id).then((customerR) => {
					APP.central.Site.fetch(selectedSite.id).then((siteR) => {
						if (boundMagic.magicalSet != undefined) {

							boundMagic.magicalSet("service", selectedService.id);
							boundMagic.magicalSet("site", selectedSite);
							boundMagic.magicalSet("customer", selectedClient);
							updateSites();
							updateServices()
						}
						if (props.addToRelated != undefined) {
							props.addToRelated([serviceR.result, siteR.result, customerR.result]);
						}
					});
				});
			});

			return true;
		}

		APP.instance.createModal(<ClientSiteServiceSelector serviceCheckable={true} callback={(selectedService, selectedSite, selectedClient) => setter(selectedService, selectedSite, selectedClient)} />, { modal_name: "Add Service" });
	}

	const updateRelateds = () => {
		const selectedSite = boundMagic.magicalGet("site");
		const selectedService = boundMagic.magicalGet("service");

		APP.central.Service.fetch(selectedService.id).then((serviceR) => {
			APP.central.Site.fetch(selectedSite.id).then((siteR) => {
				if (props.addToRelated != undefined) {
					props.addToRelated([serviceR.result, siteR.result]);
				}
			});
		});
	};

	const updateActivity = (bound, field, value) => {
		if (bound != undefined && bound[field] != undefined) {
			if (bound[field].defaultActivity != undefined && bound[field].defaultActivity != "") {
				boundMagic.magicalSet("activity", bound[field].defaultActivity);
			}
		}
	};

	let rawActivityInfo = null;
	const missingVendorActivity = props.row.items[0].vendorActivity == null;

	if (missingVendorActivity && props.row.rawLine && props.row.rawLine["Activity Description"]) {
		rawActivityInfo = <span><em>Unknown Vendor Activity:</em> {props.row.rawLine["Activity Description"]}</span>;
	}
	const saveAction = (a, b, c) => {
		let error = "";

		if (boundMagic.getParentMagic().magicalGet("paymentAdjustment") != 0) {
			if (boundMagic.getParentMagic().magicalGet("paymentAdjustmentNotes").length == 0) {
				error = "Missing payment adjustment note!";
			}
		}
		if (error) {
			return {
				then: () => {
					APP.alert('Error: ' + error);
					return { result: "error" };
				}
			};
		} else {
			return props.save(a, b, c);
		}
	}

	const refreshSomething = () => {
		_.map(window._GLOBAL_DATA_CACHE, (a) => { a.dataUID && a.dataUID.indexOf("activit") >= 0 && a.fetch() });
	};

	const newVendorActivity = () => {
		APP.instance.createModal(<EditVendorActivity vendorActivity={{ __new: true }} />, { vendor: props.vendor }, { afterClose: () => refreshSomething() });
	};

	const newWSActivity = () => {
		APP.instance.createModal(<EditActivity vendorActivity={{ __new: true }} />, {}, { afterClose: () => refreshSomething() });
	};

	// return <InputSelect optionsCollection={vendorActivitiesOptions} labelKey="activity" field="vendorActivity" onChange={assignVendorActivity}  store="object"/>

	const vendorContractLineActivity = ["test1"];

	return <div key="line-edit" className="ap-line-edit">
		<h2>Line #{props.row._keyPathPos + 1} Details</h2>
		<BoundCommit initialValue={props.row} commit={saveAction} className="basicForm">

			<InputDecimal field="paymentAdjustment" name="Payment Adjustment" format="currency" />
			<InputText field="paymentAdjustmentNotes" name="Payment Adjustment Notes" />

			<FlexBreak />

			<Bound to={null} parentArray="items" parentArrayIdx={0} init={(bound) => _setBoundMagic(bound)}>
				<InputText field="description" name="Description" inputType="textarea" />
				<FlexBreak />
				<LookupInputSelect what="customer" field="customer" name="Client" showEmpty={true} store="object" onChange={() => updateSites()} disabled={true} />
				<InputSelect field="site" name="Site" showEmpty={true} labelKey="name" optionsCollection={sites} onChange={() => updateServices()} store="object" />
				<InputSelect field="service" name="Service" showEmpty={true} labelKey="description" optionsCollection={services} onChange={() => updateRelateds()} store="object" />
				<Button onClick={() => openClientSiteSelector()}>Lookup Service</Button>
				<FlexBreak />
				<DatePicker field="periodFrom" name="Service Period From" monthsShown={2} showPreviousMonths={true} />
				<DatePicker field="periodTo" name="Service Period To" monthsShown={2} showPreviousMonths={true} />
				<LookupInputSelect what="lookup" field="activity" name="WS Activity" appendable={true} handleAppendClicked={newWSActivity} />
				{/*<LookupInputSelect what="activity_definition" fetch={{status: "active"}} display="activity" field="activity" name="WS Activity" bound={true} showEmpty={false} handleAppendClicked={newWSActivity} />*/}
				<div className='lineEditFlex'>
					<div className='lineEditContainer'>
						<h3>This Line</h3>
						<LookupInputSelect what="vendor_activity" filter={(r) => r.vendor && r.vendor.id == props.vendor} display="activity" field="vendorActivity" name="Vendor Activity" store="object" onChange={(bound, field, value) => updateActivity(bound, field, value)} appendable={true} handleAppendClicked={newVendorActivity} />
						{rawActivityInfo}
						<FlexBreak />
						<SubTotal />
					</div>
					<div className='lineEditContainer'>
						<h3>Vendor Contract</h3>
						<VendorContractSubTotal rawActivityInfo={rawActivityInfo} />
					</div>
				</div>
				<FlexBreak />
			</Bound>
		</BoundCommit>
	</div>
}

const SubTotal = (props) => {
	const boundMagic = useContext(DataContext);
	const [subTotal, setSubTotal] = useState(boundMagic.magicalGet("extendedPrice", 0));
	const [grandTotal, setGrandTotal] = useState(0);

	const offlineUpdateTotals = () => {
		const tax = boundMagic.magicalGet("taxId", null);

		let newSubTotal = boundMagic.magicalGet("quantity", 0);
		let newTotal = 0;
		newSubTotal = newSubTotal * boundMagic.magicalGet("unitPrice", 0);

		if (tax != null) {
			newTotal = newSubTotal * (tax.rate / 100);
		} else {
			newTotal = newSubTotal;
		}

		setSubTotal(newSubTotal);

		const taxPercent = boundMagic.magicalGet("taxId", { rate: 0 }).rate / 100;
		if (taxPercent == 0) {
			setGrandTotal(newSubTotal)
		} else {
			setGrandTotal(newSubTotal * (1 + taxPercent));
		}
	}

	const updateTotals = () => {
		const convolutedMess = boundMagic.to._ourMagic.parentMagic.to;

		APP.central.APInvoice.prepLine(convolutedMess).then((r) => {
			const subTotal = r.result.items[0].extendedPrice
			const taxTotal = _.sum(_.values(r.result.items[0].taxes));
			setSubTotal(subTotal);
			setGrandTotal(subTotal + taxTotal);
		});
	}

	useEffect(() => {
		boundMagic.magicalState("taxId", updateTotals, { skipInitialSet: true });
		boundMagic.magicalState("quantity", updateTotals, { skipInitialSet: true });
		boundMagic.magicalState("unitPrice", updateTotals, { skipInitialSet: true });
		boundMagic.magicalState("unitPricePer", updateTotals, { skipInitialSet: true });
		boundMagic.magicalState("vendorActivity", updateTotals, { skipInitialSet: true });
	}, []);


	const taxId = boundMagic.magicalGet("taxId", null);
	let taxes = null;
	if (taxId != null) {
		taxes = <div key={taxId.id}>{taxId.name} {taxId.rate + "%"} <NiceCurrency>{subTotal * (taxId.rate / 100)}</NiceCurrency></div>;
	}

	return <div key="subtotal" className="subtotal-component">
		<LookupInputSelect what="taxes" field="taxId" name="Tax" store="object" />
		<div className="line-price">
			<InputDecimal field="quantity" name="Quantity" />
			<InputText field="unitPrice" name="Unit Price" format="currency" />
			<LookupInputSelect what="lookup" field="unitPricePer" name="Per" />
			<div className="subtotal field">
				<NiceCurrency>{subTotal}</NiceCurrency>
			</div>
		</div>
		<div className="taxes">
			{taxes}
		</div>
		<div className="total">
			Total: <NiceCurrency>{grandTotal}</NiceCurrency>
		</div>
	</div>
}

const VendorContractSubTotal = (props) => {
	const boundMagic = useContext(DataContext);
	const [vendorActivity, setVendorActivity] = useState([]);
	const [item, setItem] = useState(
		(boundMagic.magicalGet("errorsAndInconsistencies", []).length > 0
			&& boundMagic.magicalGet("errorsAndInconsistencies", [])[0].relatedItem)
			? boundMagic.magicalGet("errorsAndInconsistencies", [])[0].relatedItem
			: boundMagic.magicalGet("createdFromLineItem", null) != null
				? boundMagic.magicalGet("createdFromLineItem", null)
				: {}
	);
	const [difference, setDifference] = useState(0);
	const [taxId, setTaxId] = useState(null);

	const updateTotals = () => {
		const toUpdate = { tempVendActivity: "", tempItem: null, tempDifference: 0, tempTaxId: null }

		if (boundMagic.magicalGet("errorsAndInconsistencies", []).length > 0 && boundMagic.magicalGet("errorsAndInconsistencies", [])[0].relatedItem) {
			toUpdate.tempItem = boundMagic.magicalGet("errorsAndInconsistencies", [])[0].relatedItem;
			toUpdate.tempVendActivity = toUpdate.tempItem.vendorActivity.activity;
			toUpdate.tempTaxId = toUpdate.tempItem.taxId ? toUpdate.tempItem.taxId : null;
		} else if (boundMagic.magicalGet("createdFromLineItem", null)) {
			toUpdate.tempItem = boundMagic.magicalGet("createdFromLineItem", null);
			toUpdate.tempVendActivity = toUpdate.tempItem.vendorActivity.activity;
			toUpdate.tempTaxId = toUpdate.tempItem.taxId ? toUpdate.tempItem.taxId : null;
		}
		toUpdate.tempDifference = (boundMagic.to.unitPrice ? boundMagic.to.unitPrice : 0) - (toUpdate.tempItem && toUpdate.tempItem.unitPrice ? toUpdate.tempItem.unitPrice : 0);

		setTaxId(toUpdate.tempTaxId);
		setItem(toUpdate.tempItem);
		setDifference(toUpdate.tempDifference);
		setVendorActivity([toUpdate.tempVendActivity]);
	};

	useEffect(() => {
		boundMagic.magicalState("taxId", updateTotals, { skipInitialSet: true });
		boundMagic.magicalState("quantity", updateTotals, { skipInitialSet: true });
		boundMagic.magicalState("unitPrice", updateTotals, { skipInitialSet: true });
		boundMagic.magicalState("unitPricePer", updateTotals, { skipInitialSet: true });
		boundMagic.magicalState("vendorActivity", updateTotals, { skipInitialSet: true });
		updateTotals();
	}, []);

	return (
		<Fragment>
			<UnlockToEdit>
				<InputSelect name="Vendor Activity" showEmpty={false} options={vendorActivity} />
			</UnlockToEdit>
			{props.rawActivityInfo}
			<FlexBreak />
			<div key="subtotal" className="subtotal-component">
				<InputSelect name="Tax" showEmpty={false} options={[taxId && taxId.name ? taxId.name : "Unable to Retrieve"]} disabled={true} />
				<div className="line-price">
					<Bound to={item}>
						<InputText name="Quantity" field="quantity" format="currency" disabled={true} />
						<InputText name="Unit Price" field="unitPrice" format="currency" disabled={true} />
					</Bound>
					<LookupInputSelect what="lookup:unitPricePer" defaultValue={item && item.unitPricePer ? item.unitPricePer : ""} name="Per" disabled={true} />
					<div className="subtotal field">
						{difference != 0 ? (
							<Fragment>
								<div>Unit Price Difference: </div>
								<NiceCurrency>{difference}</NiceCurrency>
							</Fragment>
						) : null}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export function ToolbarStuff({ magic, onlyVendorSites, refresh, totals }) {
	const toolbarCtx = useContext(ToolbarContext);
	const [postingToXero, setPostingToXero] = useState(false);
	const [showPostButton, setShowPostButton] = useState(true);
	const [showConsultingReport, setShowConsultingReport] = useState(false);
	const [xeroButtonText, setXeroButtonText] = useState("Post To Xero");

	const processedStatusText = "Processed"

	const makeConsultingStamp = useRef(() => { });

	useEffect(() => {
		if (magic && magic._myMagic) {

			magic._myMagic.magicalState("status", (apStatus) => {

				if (apStatus == processedStatusText) {
					setXeroButtonText(processedStatusText);
				}

				let largeAndFiltered = false;
				if ( magic._myMagic.magicalGet('largeAP') && magic._myMagic.magicalGet('largeAP')  && (onlyVendorSites > "" || magic._myMagic.magicalGet('_onlyVendorSites') ) ){
					largeAndFiltered = true;
				}

				if (apStatus == "Unposted AP" || magic._myMagic.magicalGet('apType') == 'consulting' ||  largeAndFiltered) {
					setShowPostButton(false);
				} else {
					setShowPostButton(true);
				}
			});

			magic._myMagic.magicalState("apType", (apType) => {

				if (apType == "consulting") {
					setShowConsultingReport(true);
					setShowPostButton(false);
				} else {
					setShowConsultingReport(false);
				}

			});

			makeConsultingStamp.current = (email) => {

				if (email == true) {
					APP.central.APInvoice.markToSend(magic._myMagic.magicalGet("id")).then((r) => {
						APP.alert("Consulting AP Invoice Queued");
					});
				} else {
					toolbarCtx.save().then(() => {
						APP.showLoading({ message: "Generating Stamped PDF", children: "This will take about 10 seconds.  You can minimize this dialog and the document will load when it's ready." }, () => {
							APP.central.APInvoice.generateStampingReport(magic._myMagic.magicalGet("id")).then((r) => {
								APP.socket.send(JSON.stringify(r));
							});
						});
					});
				}

			}

		}

	}, [magic]);

	const doCancel = () => {
		//console.log("our magic was", magic._myMagic);
		const approximateChanges = _.sum(_.map(magic._myMagic.deltas, (d) => _.size(d.delta) - 2));
		if (approximateChanges > 0) {
			APP.confirm("Are you sure you want to cancel your changes?  You will lose " + approximateChanges + " changes on this invoice.", "Warning - Unsaved Changes", (confirmResult) => {
				if (confirmResult) {
					window.history.back();
				}
			});
		} else {
			window.history.back();
		}

	}

	const datesOkForEachLine = (line)=>{ 
		let dateOkForLine = true;
		if(line.items && line.items.length > 0){
			var dateReg = /^\d{4}-\d{2}-\d{2}$/;		
			if(line.items[0].periodTo && line.items[0].periodFrom){
				if(! line.items[0].periodFrom.match(dateReg) || ! line.items[0].periodTo.match(dateReg)){
					dateOkForLine = false;
				}
				let fromDate = new Date(line.items[0].periodFrom)
				let toDate = new Date(line.items[0].periodTo)
				if(fromDate > toDate){
					dateOkForLine = false; 
				} 
			}
			else{
				dateOkForLine = false;
			}
		}
		
		return dateOkForLine;
	}

	const invoiceLinesRequiredFieldsOk = (wtfMagic)=>{  
		let requiredFieldsOk = wtfMagic.to.lines.every(mandatoryFieldsOkForEachLine) 
		return requiredFieldsOk;
	}
    
	const mandatoryFieldsOkForEachLine = (line)=>{ 
		let requiredFieldsOk = true;
		if(line.items && line.items.length > 0){
			if(! line.items[0].site || (line.items[0].site && ! line.items[0].site.address) 
				|| line.items[0].service == 0  || (line.items[0].service != 0 && ! line.items[0].service) 
			    || ! line.items[0].vendorActivity || ! line.items[0].periodFrom 
			    || ! line.items[0].periodTo || (line.items[0].quantity != 0 && ! line.items[0].quantity) 
				|| (line.items[0].unitPrice != 0 && ! line.items[0].unitPrice) 
				|| !line.items[0].unitPricePer || !line.items[0].taxId){   
				requiredFieldsOk = false;   
			}
		}		
		return requiredFieldsOk;
	}


	const invoiceDatesOk=(wtfMagic)=>{		
		let datesOk = wtfMagic.to.lines.every(datesOkForEachLine)
		return datesOk;
	}
    const checkAPLinesFields = (wtfMagic) =>{ 
		let invoiceLineFieldsOk = true;
		if( wtfMagic.to && wtfMagic.to.lines && wtfMagic.to.lines.length > 0){ 
			
		    if(!invoiceDatesOk(wtfMagic)){
			   invoiceLineFieldsOk = false;
			   APP.alert("Failed to Save. Make sure the From and To dates are entered and correct for all the invoice lines.");
		    }
			else if(! invoiceLinesRequiredFieldsOk(wtfMagic)){
				invoiceLineFieldsOk = false;
				APP.alert("Failed to Save. Make sure all the required fields have values for all the invoice lines.");   
			}
		}
		return invoiceLineFieldsOk;		
	}

	const checkAPBillingFields = (wtfMagic)=>{ 
		let clientFieldsOk = true;
		let yearMonthDay = /^\d{4}-\d{2}-\d{2}$/;	 
		let yearMonth = /^\d{4}-\d{2}$/;		
		let to = wtfMagic.to;
		if(to){
				//invoice date, billing period, invoice number, status, invoice preparer, AP type, vendor tax total
			if(  ! to.invoiceDate || ! to.billingPeriod || ! to.externalInvoiceNumber  
				|| (to.externalInvoiceNumber && to.externalInvoiceNumber.length == 0)
				|| ! to.status || ! to.apType || to.vendorTaxTotal === "" ||  to.vendorTotal === ""){
					clientFieldsOk = false; 
					APP.alert("Failed to Save. Make sure all the required fields have values.");     
			}
				//invoice date, date received , billing period 
			else if(((to.invoiceDate &&! to.invoiceDate.match(yearMonthDay)) 
				|| (to.dateReceived && ! to.dateReceived.match(yearMonthDay)) 
				|| (to.billingPeriod && ! to.billingPeriod.match(yearMonth))))   
			{
				clientFieldsOk = false;
				APP.alert("Failed to Save. Make sure values entered as date have correct format.");   
			}
		}		 
		return clientFieldsOk; 
	}

	const checkAPInvoiceFields = (wtfMagic) =>{
		let apFieldsOk = true;
		if( ! checkAPLinesFields(wtfMagic) || ! checkAPBillingFields(wtfMagic)){ 
			apFieldsOk = false;
		}		
		return apFieldsOk;		 
	}

	const doRefreshAP = () => {
		const wtfMagic = magic._myMagic;
		if(checkAPInvoiceFields(wtfMagic)){
			toolbarCtx.save().then(() => {
			
				APP.confirm("All AP Changes will be reverted - do you want to continue?", "Are you Sure?", (ok) => {
					if (ok) {
						APP.central.Snowman.rerollSnowball(wtfMagic.magicalGet("id"), onlyVendorSites, "ap").then((r) => {
							APP.alert("AP Updated");
							refresh && refresh();
						});
					}
				});
			});
		}		
	}

	const doCreateAR = () => {
		const wtfMagic = magic._myMagic;
		if(checkAPInvoiceFields(wtfMagic)){
			const actuallyDoCreateAR = () => {
				toolbarCtx.save().then(() => {
					APP.central.Snowman.rerollSnowball(wtfMagic.magicalGet("id"), onlyVendorSites, "ar").then((r) => {
						if (r.result.actions && r.result.actions.length > 0) {
							const message = _.map(r.result.actions, (a) => a.split("|")[0]).join(", ");
							APP.alert(message);
							refresh && refresh();
						}
					});
				});
			}
	
			let foundErrorsOrInconsistencies = false;
			const lines = wtfMagic.magicalGet("lines", null) ? wtfMagic.magicalGet("lines", null) : [];
			let issuesMessage = "";
	
			_.forEach(lines, (line) => {
				_.forEach(line.items, (item) => {
					const theLine = line;
					if (
						item.errorsAndInconsistencies &&
						item.errorsAndInconsistencies.length > 0 &&
						theLine &&
						(_.isEmpty(theLine.paymentAdjustmentNotes) || (theLine.paymentAdjustmentNotes && theLine.paymentAdjustmentNotes.length < 10))
					) {
						foundErrorsOrInconsistencies = true;
						_.forEach(item.errorsAndInconsistencies, (issue) => {
							issuesMessage += item.serviceFriendlyId + " - " + issue.status + ", ";
						});
					}
				});
			});
	
			if (foundErrorsOrInconsistencies) {
				if (wtfMagic.to.internalNotes && wtfMagic.to.internalNotes.length > 10) {
					APP.confirm("This Invoice has unresolved Issues: " + issuesMessage + "Are you sure you want to create an AR?", (res) => {
						if (res == true) {						
							actuallyDoCreateAR();
						}
					});
				} else {
					APP.alert("This Invoice has unresolved Issues: " + issuesMessage + "Please add Internal Notes (min. 10 characters) to this Invoice before creating an AR.");
				}
			} else {			
				actuallyDoCreateAR();
			}
		}
		
	};

	const doApprove = () => {
		const wtfMagic = magic._myMagic;
		if(checkAPInvoiceFields(wtfMagic)){
			const doActualApprove = () => {
				setPostingToXero(true);
				wtfMagic.magicalSet("status", processedStatusText);
				const args = {};
				args.thing = wtfMagic.to;
				/* 
				const taxlessItems = _.filter(_.flatten(_.map(wtfMagic.magicalGet("lines", []), (line)=>line.items)), (item)=>item.taxId == null);
				if(taxlessItems.length > 0){
				  APP.confirm(taxlessItems.length + " missing taxes. Continue?", "Warning", ()=>doPost(args, wtfMagic));
				} else {
				  doPost(args, wtfMagic);
				} 
				*/
				doPost(args, wtfMagic);
			};
	
			const doCheckStatus = () => {
				// could also check wtfMagic.magicalGet("xeroUUID", null) ? might be better not sure.
				if (wtfMagic.magicalGet("status", null) == processedStatusText) {
					APP.confirm('This Invoice is marked as "' + processedStatusText + '". Are you sure you want to post this invoice again?', (res) => {
						if (res == true) {
							doActualApprove();
						}
					});
				} else {
					doActualApprove();
				}
			}
	
			// make sure invoice has a date
			const invoiceDate = wtfMagic.magicalGet("invoiceDate", null);
			if (invoiceDate == null || invoiceDate.trim().length <= 0) {
				APP.alert("Invoice Date Not Set", "Aborting");
				return false;
			}
	
			// make sure invoice total is balanced
			const invoiceVendorTotal = wtfMagic.magicalGet("vendorTotal", null);
			const invoiceBalancing =
				(_.isNumber(invoiceVendorTotal) ? invoiceVendorTotal : 0) -
				(_.isNumber(totals.grandTotal) ? totals.grandTotal : 0) -
				(_.isNumber(totals.otherLinesGrandTotal) ? totals.otherLinesGrandTotal : 0);
	
			if (invoiceBalancing > 1 || invoiceBalancing < -1) {
				APP.alert("Invoice total out of balance by over $1", "Aborting");
				return false;
			}
	
			if (invoiceBalancing > 0.05 || invoiceBalancing < -0.05) {
				APP.confirm('Invoice total is not balanced, difference is: $' + Util.roundNice(invoiceBalancing, 2) + ', Continue?', (res) => {
					if (res == true) {
						doCheckStatus();
					}
				});
			} else {
				doCheckStatus();
			}
		}		
	};

	const doPost = (args, wtfMagic) => {
		withXero(() => {
			//might need to move this to the queue way ( ARInvoice.flaggedForExport() )
			toolbarCtx.save().then(() => {
				let okToPost = true;
				let issue = "";

				//wtf is thing?
				if (args.thing.invoiceDate == null) {
					okToPost = false;
					issue = "Provide an AP invoice date to continue";
				}

				if (okToPost == false) {
					APP.alert("AP Issue: " + issue);
					setPostingToXero(false);
					return;
				}

				APP.central.Xero.sync(args).then((r) => {
					//console.log("xero sync got back", r);
					if (r.result != null && r.result.xeroUUID) {
						wtfMagic.magicalSet("xeroUUID", r.result.xeroUUID);

						const created = r.result;
						APP.alert("Invoice created");
						setPostingToXero(false);
					} else {
						APP.alert("Invoice was not created - " + r.result);
						setPostingToXero(false);
					}
				});
			});
		});
	};

	const viewMargins = () => {
		APP.instance.createModal(<APMarginList id={magic._myMagic.to.id} />)
	}

	const APMarginList = (props) => {
		const [data, setData] = useState(null);

		useEffect(() => {
			APP.central.APInvoice.getAPMarginData(props.id).then((r) => {
				setData(r.result.rows)
			})
		}, [])
		
		const openInvoiceLinks = (row) => {
			APP.instance.createModal(<InvoiceLinks row={row} />)
		}
		
		const InvoiceLinks = (props) => {
			console.log(props.row)
			return <div>
				<h1>AP Invoices</h1>
				{props.row.ap_ids.split(", ").map((id) => {
					return <div><Link to={"/ui/apinvoice/" + id}>AP {id}</Link></div>
				})}
				<h1>AR Invoices</h1>
				{props.row.ar_ids.split(", ").map((id) => {
					return <div><Link to={"/ui/arinvoice/" + id}>AR {id}</Link></div>
				})}
			</div>
		}
		
		return <div>
			<h1>Margin Data</h1>
			<Tabular data={data}>
			<TabularColumn title="Customer" data={(row) => <Link to={"/ui/clients/" + row.customer_friendly_id}>{row.customer_name}</Link>} />
                <TabularColumn title="Industry" data={(row) => row.environmental_industry} />
                <TabularColumn title="Site" data={(row) => <Link to={"/ui/sites/detail/" + row.site_friendly_id}>{row.site_name}</Link>} />
                <TabularColumn title="Service" data={(row) => <Link to={"/ui/services/edit/" + row.service_id}>{row.service_friendly_id}</Link>} />
                <TabularColumn title="Manager" data={(row) => row.regional_account_manager} />
                <TabularColumn title="Billing Period" data={(row) => row.billing_period} />
                <TabularColumn title="AR Total" data={(row) => <NiceCurrency roundedPlaces={0}>{row.ar_total}</NiceCurrency>} />
                <TabularColumn title="AP Total" data={(row) => <NiceCurrency roundedPlaces={0}>{row.ap_total}</NiceCurrency>} />
                <TabularColumn title="Margin" data={(row) => <NiceCurrency roundedPlaces={0}>{row.margin}</NiceCurrency>} />
                <TabularColumn title="Margin %" data={(row) => <NicePercent places={0}>{row.margin_percentage}</NicePercent>} />
                <TabularColumn title="Invoices" data={(row) =>
                    <Icon onClick={() => openInvoiceLinks(row)} icon="file-invoice-dollar"/>
                }/>
			</Tabular>
				
		</div>
	}

	if (showPostButton == false) {
		return <Fragment>
			<Button onClick={() => doCancel()}>Cancel</Button>

			{/** need to only show this for some comp types */}
			<Button onClick={() => doCreateAR()}>Create AR</Button>

			{showConsultingReport ? <Button onClick={() => makeConsultingStamp.current()}>View Report</Button> : null}
			{showConsultingReport ? <Button onClick={() => makeConsultingStamp.current(true)}>Send Report</Button> : null}

			

		</Fragment>;
	}

	return <Fragment>
		{postingToXero ? <Button>Posting...</Button> : <Button onClick={() => doApprove()}>{xeroButtonText}</Button>}
		<Button onClick={() => doCancel()}>Cancel</Button>
		<Button onClick={() => doRefreshAP()}>Refresh AP</Button>
		<Button onClick={() => doCreateAR()}>Create AR</Button>

		{showConsultingReport ? <Button onClick={() => makeConsultingStamp.current()}>View Report</Button> : null}
		<Button onClick={() => viewMargins()}>View Margins</Button>
	</Fragment>;
}

const CopyWrapper = function ({ inv, createFromCopy }) {
	return <Bound to={inv}>
		<CopyAPThing defaultVendor={inv.vendor ? inv.vendor.id : 0} copyFn={createFromCopy} />
	</Bound>;
}
export default function APInvoiceEdit(props) {
	const nav = useNavBar("Accounts Payable Invoice");
	const [inv, setInv] = useState({ lines: [] });
	const [xeroStatus, setXeroStatus] = useState('Posted');
	const [loadedInv, setLoadedInv] = useState({ lines: [] });
	const [loadedLines, setLoadedLines] = useState([]);
	const [shouldDisplayRow, setShouldDisplayRow] = useState(() => (row) => { return "contents"; });
	const [related, setRelated] = useState([]);
	const boundRef = useRef();
	const gridRef = useRef();
	const [raw, setRaw] = useState("");
	const [vendor, setVendor] = useState(0);
	const [vendorActivityList, vendorActivityListDS] = useData("vendor_activity-" + vendor, { vendor: vendor });
	const invId = parseInt(props.match.params.id);
	const [totals, setTotals] = useState({});
	const [entitiesOnInvoice, setEntitiesOnInvoice] = useState([]);
	const pressed = useRef({});
	const [ARMatches, setARMatches] = useState([]);
	const otherLines = useRef([]);
	const [highLevelLines, setHighLevelLines] = useState([]);
	const [xeroPaymentInfo, setXeroPaymentInfo] = useState([]);

	const [vendorTaxTotal, setVendorTaxTotal] = useState(0);
	const [vendorTotal, setVendorTotal] = useState(0);

	const [onlyVendorSites, setOnlyVendorSites] = useState(props.match.params.sites ? props.match.params.sites : null);
	const [editingLines, setEditingLines] = useState(false);
	const [showingHighLevelLines, setShowingHighLevelLines] = useState(onlyVendorSites > "" ? false : true);
	const [showingPartialInvoice,setShowingPartialInvoice] = useState(false);
	const lastSite = useRef(null);

	const [foundInvoiceFile, setFoundInvoiceFile] = useState(false);
	const [possibleDuplicates, setPossibleDuplicates] = useState([]);

	const [customer, setCustomer] = useState(null);
	const [rerunningUpdateTotal, setRerunningUpdateTotal] = useState(0);

	const [invoiceTiming, setInvoiceTiming] = useState(null)

  const [invoiceIsCopy, setInvoiceIsCopy] = useState(false);

	useEffect(() => {
		if (inv != null && inv != undefined && inv.lines.length > 0) {
			setCustomer(inv.lines[0].items[0].customer)

      //check each inv.lines for a source, if it starts with "AP:" then take the set of numbers after the colon and compare it it inv.id, ignore anything after a second set of columns. if it matches, setIsCopy to true
      _.forEach(inv.lines, (line) => {
        if (line.source && line.source.startsWith("AP:")) {
          const sourceParts = line.source.split(":");
          if (sourceParts.length > 1) {
            const sourceId = parseInt(sourceParts[1]);
            if (sourceId != inv.id) {
              setInvoiceIsCopy(true);
            }
          }
        }
      });
		}
	}, [inv])

	useEffect(() => {
		if (customer != null) {
			APP.central.Customer.fetchInvoiceTiming(customer.id).then((r) => {
				setInvoiceTiming(r.result);
			})
		}
	}, [customer])

	useEffect(() => {
		vendorActivityListDS.callback(() => {
			if (gridRef.current != null) {
				gridRef.current.refresh();
			}
		});
	}, []);

	useEffect(() => {
		APP.central.APInvoice.downloadVendorInvoice(invId).then((r) => {
			if (r.result != undefined) {
				setFoundInvoiceFile(true);
			} else {
				setFoundInvoiceFile(false);
			}
		});
	}, [invId]);

	useEffect(() => {
		setLoadedLines(loadedInv.lines);

		if (loadedInv.vendorTotal === null || loadedInv.vendorTotal == 0) { //it might not be loaded, in which case there is no vendorTotal field
			setEditingLines(true);
		}
	}, [loadedInv]);

	useEffect(()=>{
		const totalAttempts = 10;
		if(rerunningUpdateTotal >= 0 && rerunningUpdateTotal < totalAttempts){			
			setTimeout(() => updateInvoiceTotals(), 250);
		} else if(rerunningUpdateTotal >= totalAttempts){			
			setRerunningUpdateTotal(-1); //we gave up
		}
	}, [rerunningUpdateTotal]);

	const updateInvoiceTotals = () => {
		let total = 0;
		let totalMargin = 0;
		let totalTax = 0;
		let totalAdjustment = 0;
		let totalAdjustmentTax = 0;
		let otherTotal = 0;
		let otherLinesTotalTax = 0;
		let otherLinesAdjustment = 0;

		const apBound = APP.registeredBoundMagics["AP-" + invId];

		let tempCusts = [];
		let tempSites = [];
		const sawLines = [];
		if (apBound && apBound.magicalGet("lines").length > 0) {
			_.forEach(_.range(0, apBound.magicalGet("lines").length), (count) => {
				const l = apBound.getBound("lines", count);
				const item = l.items[0];
				sawLines.push(l.id);

				if (item.customer != undefined) {
					tempCusts.push(item.customer.id);
				}
				if (item.site) {
					tempSites.push(item.site.id);
				}

				total += item.extendedPrice;
				totalAdjustment += l.paymentAdjustment;
				totalMargin += l.totalMargin;
				if (item.taxId != undefined) {
					totalAdjustmentTax += (l.paymentAdjustment * item.taxId.rate) / 100;
					totalTax += (item.extendedPrice * item.taxId.rate) / 100;
				}
			});
		} else {
			if (apBound && apBound.magicalGet("largeAP") != true) {
				setRerunningUpdateTotal(rerunningUpdateTotal+1);
			}
			//don't do anything else if we have zero lines
			//ok lets do it for real
			//return;
		}

		if (otherLines.current.length > 0) {
			_.map(otherLines.current, (line) => {
				if (_.indexOf(sawLines, line.id) == -1) {
					otherTotal += line.extended_price;
					otherLinesTotalTax += line.expected_tax;
					otherLinesAdjustment += line.payment_adjustment;
					//totalMargin += l.totalMargin;
				}
			});
		}

		viewLastSite();

		//for notes
		let tempEntitiesOnInvoice = [];
		tempEntitiesOnInvoice = _.concat(
			_.uniqBy(
				_.map(tempCusts, (custId) => {
					return { __type: "Customer", id: custId };
				}),
				"id"
			),
			_.uniqBy(
				_.map(tempSites, (siteId) => {
					return { __type: "Site", id: siteId };
				}),
				"id"
			)
		);
		setEntitiesOnInvoice(tempEntitiesOnInvoice);

		setTotals({
			total: total,
			totalMargin: totalMargin,
			totalTax: totalTax,
			grandTotal: total + totalTax,
			otherTotal: otherTotal,
			otherLinesTotalTax: otherLinesTotalTax,
			otherLinesGrandTotal: otherTotal + otherLinesTotalTax,
			totalAdjustment: totalAdjustment,
			totalAdjustmentTax: totalAdjustmentTax,
			grandTotalAdjustment: totalAdjustment + totalAdjustmentTax,
		});
	}

	const viewLastSite = () => {
		if (lastSite.current != null && showingHighLevelLines) {
			const el = window.document.getElementById("site-" + lastSite.current);
			if (el != null) {
				el.scrollIntoView();
				lastSite.current = null;
			} else {
				setTimeout(viewLastSite, 250);
			}
		}
	};

	const fetchData = () => {
		APP.central.APInvoice.getInvoice(invId, onlyVendorSites).then((r) => {
			setXeroStatus(r.result.entity.xeroStatus);

			/** if (_.size(r.result.extras.matches) > 0){
				r.result.entity.arLink = r.result.extras.matches[0].ar_invoice_id
				for (var i = 0; i < r.result.entity.lines.length; i++) {
					r.result.entity.lines[i].items[0].arLink = r.result.extras.matches[i].ar_invoice_id //@brian could be an issue later
				}
			}**/
			setARMatches(r.result.extras.matches);
			if (r.result.entity.largeAP == false) {
				setShowingHighLevelLines(false);
				setShowingPartialInvoice(true);
			}
			otherLines.current = r.result.extras.lines;
			setHighLevelLines(r.result.extras.lines);
			setXeroPaymentInfo(r.result.extras.xeroPaymentInfo);
			setInv(r.result.entity);
			setLoadedInv(r.result.entity);
			setRelated(r.result.related);
			setRaw(r.message[2]);

			handleSetVendor(r.result.entity, "vendor");

			if (gridRef.current) {
				gridRef.current.refresh();
			}

			setTimeout(() => updateInvoiceTotals(), 1000);
		});
	}

	useEffect(() => {
		fetchData();
	}, [invId, onlyVendorSites]);

	const saveLine = (a, b, c, rowIdx) => {
		const them = c;
		const apBound = APP.registeredBoundMagics["AP-" + invId];

		APP.central.APInvoice.prepLine(them).then((r) => {
			if (r.result) {			
				apBound.getBound(them._keyPath, them._keyPathPos)._ourMagic.replaceTo(r.result);
				gridRef.current.refresh();
			}
		});
	}



	const handleSetVendor = (bound, field, value) => {
		//we could be passed either an object, or an id. 
		if (bound[field] != null) {
			if (typeof bound[field] == "object") {
				if (bound[field].id != vendor) {
					setVendor(bound[field].id);
				}
			} else if (value != vendor) {
				setVendor(parseInt(bound[field]));
			}
		}
	};



	const changedSomething = (diff, line, opts) => {  
		const ignores = ['paymentAdjustmentNotes'];
		if (ignores.indexOf(_.keys(diff)[0]) >= 0) {
			return;
		}
		if(line && line.items && line.items[0]){
			if((line.items[0].unitPrice && line.items[0].unitPrice === "-") || line.items[0].quantity && line.items[0].quantity === "-" ){
				return;
			}
		}

		//const newInv = _.cloneDeep(inv);
		const apBound = APP.registeredBoundMagics["AP-" + invId];

		APP.central.APInvoice.prepLine(line).then((r) => {
			const rowIdx = _.findIndex(inv.lines, (l) => { return l.id == r.result.id });
			if (rowIdx >= 0) {
				apBound.getBound(line._keyPath, line._keyPathPos)._ourMagic.replaceTo(r.result);
			} else {
				apBound.getBound(line._keyPath, line._keyPathPos)._ourMagic.replaceTo(r.result);
			}
			if (gridRef.current != null) {
				gridRef.current.refresh();
			}
			updateInvoiceTotals();
		});
	};

	const orderBy = ["id"]; //["items[0].service", "id", "items[0].periodFrom"];
	const orderByDirection = ["asc"]; //["asc", "desc", "asc"];

	const handleCellAction = (event, rowDetails, gridContext, action) => {
		if(rowDetails == null || action == null){
			return;
		}
		let newItem = { id: 0 };
		let append = false;

		if (rowDetails.row == undefined) {
			append = true;
		} else if (rowDetails.row.items.length > 0) {
			if (action == "delete") {
				APP.instance.openConfirmDialog("Make sure to save changes on other lines before this delete. Do you want to continue?", "Deleting is permanent. Unsaved data of the lines will be lost.", (res) => {
					if (res == true) {
						if (rowDetails.row.id <= 0) {
							const forceUpdate = true;
							boundRef.current._myMagic.magicalSplice(rowDetails.row._keyPath, rowDetails.row._keyPathPos, forceUpdate);
							setLoadedInv(boundRef.current._myMagic.to);
						} else {
							APP.central.APInvoice.deleteLine(rowDetails.row.id).then((r) => { 
								setLoadedInv(r.result);
							});							
						}
					}
				});

				return;
			} else if (action == "clear") {
				const theItem = rowDetails.row._ourMagic.magicalGet('items')[0];

				newItem.id = theItem.id;
				newItem.lineItemTypeRelatedId = theItem.lineItemTypeRelatedId;
				newItem.service = theItem.service;
				newItem.site = theItem.site;
				newItem.customer = theItem.customer;
				newItem.vendor = theItem.vendor;

				// rowDetails.row.items[0] = newItem;
				// boundRef.current._myMagic.getBound("lines",rowDetails.row._keyPathPos)._ourMagic.replaceTo( rowDetails.row );

				boundRef.current._myMagic.getBound("lines", rowDetails.row._keyPathPos)._ourMagic.magicalSet('items', [newItem]);
				setLoadedInv(boundRef.current._myMagic.to);
				return;
			}

			if (action == "copy") {
				newItem = { ...rowDetails.row.items[0], ...newItem };
				delete newItem._keyPathPos;
				delete newItem._ourMagic;
				delete newItem.lineItemType;
				delete newItem.lineItemTypeRelatedId;

				append = true;
			} else {
				const theItem = rowDetails.row._ourMagic.magicalGet('items')[0];

				newItem.service = theItem.service;
				newItem.site = theItem.site;
				newItem.customer = theItem.customer;
				if (newItem.customer == null) {
					if (boundRef.current != undefined) {
						newItem.customer = boundRef.current._myMagic.magicalGet("lines")[0]
					}
				}
				newItem.vendor = theItem.vendor;

				append = true;
			}
		}

		if (append == true) {
			let currentSize = boundRef.current._myMagic.magicalGet("lines").length;
			currentSize++;
			const newRow = { id: -1 * currentSize, items: [newItem], __type: "APInvoiceLine", source: "AP:" + invId };

			boundRef.current._myMagic.magicalAppend("lines", newRow); //, orderBy, orderByDirection);
			setLoadedInv(boundRef.current._myMagic.to);
		}
	};

	if (inv == null) {
		return <div>loading</div>;
	}

	const setBound = (ref) => {
		boundRef.current = ref.current;

		boundRef.current._myMagic.magicalState("vendorTaxTotal", (val) => {
			if (val == null) {
				val = 0;
			}
			setVendorTaxTotal(val);
		});

		boundRef.current._myMagic.magicalState("vendorTotal", (val) => {
			if (val == null) {
				val = 0;
			}
			setVendorTotal(val);
		});

		boundRef.current._myMagic.magicalState("externalInvoiceNumber", (invoiceNumber) => {
			const vendorId = boundRef.current._myMagic.magicalGet("vendor").id;
			const ourId = boundRef.current._myMagic.magicalGet("id");

			APP.central.APInvoice.findDuplicates(vendorId, invoiceNumber, ourId).then((r) => {
				setPossibleDuplicates(r.result);
			});

		})
	}

	const gridHotKeyDown = (e) => {
		if (e.key == "Control") {
			if (pressed.current[e.key] != undefined) {
				const diff = moment().diff(pressed.current[e.key]);

				if (diff > 2000) {
					//console.log("TODO Popup commands!");
				}

				return;
			}

			pressed.current[e.key] = moment();
		}
	}

	const gridHotKeyUp = (e, row, col, props) => {
		let handled = false;
		let action = "";

		if (e.key == "Enter") {
			handled = true;
			action = "new";
		} else if (e.ctrlKey == true && e.shiftKey == true) {
			if (e.key == " ") {
				handled = true;
				action = "copy";
			} else if (e.key == "Backspace") {
				handled = true;
				action = "clear";
			} else if (e.key == "D") {
				handled = true;
				action = "delete";
			}
		}

		if (handled == true) {			
			handleCellAction(e, props.ctx.findWhatIsAt(props.gridRow, props.gridCol), null, action);
		}

		return handled;
	}

	const handleRegisterGrid = (ctx) => {
		gridRef.current = ctx;

		gridRef.current.customKeyUp = gridHotKeyUp;
		gridRef.current.customKeyDown = gridHotKeyDown;
	}

	let actionItems = undefined;
	if (inv.id > 0) {
		actionItems = <>
			<Notes model={inv} entities={entitiesOnInvoice} types={["APInvoice"]} />
			<Tasks model={inv} entities={entitiesOnInvoice} visibility={["billing", "ap_billing"]} />
			<ChangeLogs model={inv} />
		</>;
	}

	const handleFilterLines = (value) => {
		let lines = null;
		if (value == "Issues Only") {
			lines = _.filter(loadedInv.lines, (line) => {
				if (line.items != undefined) {
					return _.filter(line.items, (item) => {
						if (item != undefined && item.errorsAndInconsistencies != undefined) {
							return item.errorsAndInconsistencies.length > 0;
						}

						return false;
					}).length > 0;
				}

				return false;
			});

			setShouldDisplayRow(() => (row) => {
				const shouldDisplay = _.filter(row.items, (item) => {
					if (item != undefined && item.errorsAndInconsistencies != undefined) {
						return item.errorsAndInconsistencies.length > 0;
					}

					return false;
				}).length > 0;

				if (shouldDisplay == true) {
					return "contents";
				}

				return "none";
			});
		} else {
			lines = loadedInv.lines;

			setShouldDisplayRow(() => (row) => {
				return "contents";
			});
		}

		setLoadedLines(lines);
	}

	const downloadVendorInvoice = () => {
		APP.central.APInvoice.downloadVendorInvoice(inv.id).then((r) => {
			if (r.result != undefined) {
				const file = r.result;
				window.open("/file/download/" + file.storageIdentifier + "/" + file.filename);
			} else {
				APP.alert("No Vendor Invoice found.");
			}
		});
	};

	const openPDFPreview = () => {
		APP.central.APInvoice.downloadVendorInvoice(inv.id).then((r) => {
			if (r.result != undefined) {
				const file = r.result;
				APP.instance.createModal(<SimplePDFPreview file={file} />, { modal_name: "PDF:" + file.filename });
			} else {
				APP.alert("No Vendor Invoice found.");
			}
		});

	}

	const uploadVendorInvoice = (file) => {
		setFoundInvoiceFile(true);
	}

	const addToRelated = (newThings) => {
		const newRelated = related.slice();
		if (_.isArray(newThings)) {
			_.forEach(newThings, (thing) => {
				newRelated.push(thing);
			});
		} else {
			newRelated.push(newThings);
		}

		setRelated(newRelated);
	}

	const refreshFromSave = () => {
		fetchData();
	}

	const openSite = (row) => {
		setOnlyVendorSites(row.site);
		boundRef.current._myMagic.magicalSet("_onlyVendorSites", row.site);
		setEditingLines(true);
		setShowingHighLevelLines(false);
		lastSite.current = row.site;
	}

	const openHighLevel = (row) => {
		setOnlyVendorSites(null);
		boundRef.current._myMagic.magicalSet("_onlyVendorSites", null);
		setEditingLines(false);
		setShowingHighLevelLines(true);
	}


	const createFromCopy = (apToCopy) => {
		APP.central.APInvoice.CopyAP(apToCopy.id, 0, inv.id).then((r) => {
			if (r.result.id != undefined && r.result.id > 0) {
				fetchData();
			}
		});

	}

	const startCopy = () => {
		APP.instance.createModal(<CopyWrapper inv={inv} createFromCopy={createFromCopy} />, { modal_name: "Copy AP" });
	}


	const doVoid = () => {

		APP.instance.openConfirmDialog("Are you sure you want to VOID this AP Invoice in Xero?", "Confirm VOID", (r) => {
			if (r) {
				APP.central.Xero.voidAPInvoice(inv.id).then((r) => {
					fetchData();
				});
			}
		});

	}

	const startClearLines = () => {
		APP.central.APInvoice.ClearAP(inv.id).then((r) => {
			if (r.result.id != undefined && r.result.id > 0) {
				fetchData();
			}
		});
	}

	const getXeroPaymentTotal = () => {
		let total = 0;
		_.forEach(xeroPaymentInfo, (pmt) => {
			if (pmt.status == "AUTHORISED") {
				total += pmt.amount;
			}
		});

		return total;
	}

	const getInvoiceTiming = (invoiceTiming)=>{
		if(invoiceTiming){
			if(invoiceTiming === 'postBilled'){
				return 'Post Billed'
			  }
			  if(invoiceTiming === 'preBilled'){
				return 'Pre Billed'
			  }
		}
		else{
			return 'Not Set'
		}      
	}

  const getProcessMethod = ()=>{
    if(inv.processMethod){
      if(inv.processMethod === 'manual'){
        return 'Manual'
      }
      if(inv.processMethod === 'automatic'){
        return 'Automatic'
      }
      if(inv.processMethod === 'not_processed'){
        return 'Not Processed'
      }
    }
    else{
      return '-'
    }
  }

	return (
		<div key="edit_invoice" className="edit-ap-invoice basicForm"> 
			<ActionBar>
				<div style={{ flex: 5 }}></div>
				{actionItems}
			</ActionBar>

			<div>
			<div style={{color: '#27b2bc', fontWeight:'bold'}}>Invoice Timing: {getInvoiceTiming(invoiceTiming)}</div>  
			<div style={{color: '#27b2bc', fontWeight:'bold'}}>Processed Via: {getProcessMethod()}</div>  
			</div>
      {
        invoiceIsCopy ? <div className="copy-warning">This invoice was copied from another invoice. Double check activity dates are correct.</div> : null
      }

			<BoundCommit
				stealBound={setBound}
				listenTo={{ APInvoiceLine: changedSomething }}
				boundId={"AP-" + invId}
				initialValue={loadedInv}
				commit={APP.central.APInvoice.saveChangesFull}
				afterSave={refreshFromSave}
				toolbar={<ToolbarStuff onlyVendorSites={onlyVendorSites} magic={boundRef.current} refresh={fetchData} totals={totals} />}
			>
				<FlexRow>
					{foundInvoiceFile ? (
						<div style={{ display: "flex", gap: "5px" }}>
							<Button onClick={downloadVendorInvoice}>
								<Icon icon="file-pdf" color="white" />
								Vendor Invoice
							</Button>
							<Button onClick={openPDFPreview} className="pdf-preview-button">
								<Icon icon="file-invoice-dollar" size="2x" color="white" />
							</Button>
						</div>
					) : (
						<File onUpload={(file) => uploadVendorInvoice(file)} label="Upload Vendor Invoice" showFiles={false} />
					)}
				</FlexRow>
				
					<LookupInputSelect
						what="vendor"
						field="vendor"
						store="object"
						name="Vendor"
						onChange={(bound, field, value) => handleSetVendor(bound, field, value)} 
						showEmpty={true}					
					/>
					<DatePicker field="invoiceDate" name="Invoice Date" invoiceDate={true} monthsShown={2} showPreviousMonths={true} invReqField={true}/>
					<DatePicker field="dateReceived" name="Date Received" />
					<FlexBreak /> 
					<SimpleMonthPicker field="billingPeriod" label="Billing Period" invReqField={true}/> 
					<InputText field="externalInvoiceNumber" name="Invoice Number" invReqField={true}/>
					<InputText field="externalAccountNumber" name="Account Number" /> 
					<FlexBreak /> 
					<LookupInputSelect what="lookup:AP Invoice Status" field="status" name="Status" invReqField={true} showEmpty={false} defaultValue="WIP"/>
					<StaffSelect what="staff" field="invoicePreparer" name="Invoice Preparer"/>
					<InputSelect name="AP Type" field="apType" options={["managed", "consulting"]} showEmpty={false} defaultValue="managed" invReqField={true}/>
				
				

				<FlexBreak />  

				{/** manual ap chunks **/}   
				<ManualProcessEditors />
				<XeroLink type="APInvoice" /> 

				{possibleDuplicates && possibleDuplicates.length && possibleDuplicates.length > 0 ? <DuplicateAPDetails aps={possibleDuplicates} /> : null}

				<h2 className="with-filters">
					Line Items{" "}
					<span className="filter-thats-not-a-portal-one">
						<em style={{ fontSize: "14px" }}>Showing:</em>{" "}
						<InputSelect onChange={(to, field, value) => handleFilterLines(value)} options={["All Lines", "Issues Only"]} showEmpty={false} />
					</span>
				</h2>

				<FlexBreak />
				<div className="compact">
					{showingHighLevelLines == false ? <Icon title="All Sites" icon="line-columns" onClick={() => openHighLevel()} /> : null}
					<Icon title="Edit" disabled={xeroStatus==='Posted'} icon="pencil" onClick={() => setEditingLines(!editingLines)} />
					
					{showingHighLevelLines ? (
						<APHighLevelTabular lines={highLevelLines} openSite={openSite} />
					) : editingLines ? (
						<APLineGrid
							addToRelated={(r) => addToRelated(r)}
							handleCellAction={handleCellAction}
							handleRegisterGrid={handleRegisterGrid}
							related={related}
							saveLine={saveLine}
							vendor={vendor}
							order={orderBy}
							orderDirection={orderByDirection}
							matches={ARMatches}
							shouldDisplayRow={(row) => shouldDisplayRow(row)}
							customer={customer}
						/>
					) : (
						<APLineTabular related={related} loadedLines={loadedLines} order={orderBy} orderDirection={orderByDirection} matches={ARMatches} />
					)}
				</div>

				<FlexRow>
					<InputText field="internalNotes" inputType="textarea" name="Notes" className="" />

					<table className="balancing">
						<thead>
							<tr>
								<th>Balancing</th>
								<th>Tax</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Per Invoice Total</td>
								<td>
									<NiceCurrency>{vendorTaxTotal}</NiceCurrency>
								</td>
								<td>
									<NiceCurrency>{vendorTotal}</NiceCurrency>
								</td>
							</tr>
							<tr>
								<td>{showingHighLevelLines ? "Per Above Total" : "Per Other Total"}</td>
								<td>
									<NiceCurrency>{totals.otherLinesTotalTax}</NiceCurrency>
								</td>
								<td>
									<NiceCurrency>{totals.otherLinesGrandTotal}</NiceCurrency>
								</td>
							</tr>
							{showingHighLevelLines == false ? (
								<tr>
									<td>Per Above Total</td>
									<td>
										<NiceCurrency>{totals.totalTax}</NiceCurrency>
									</td>
									<td>
										<NiceCurrency>{totals.grandTotal}</NiceCurrency>
									</td>
								</tr>
							) : null}
							<tr>
								<td>Difference</td>
								<td>
									<NiceCurrency>{vendorTaxTotal - totals.totalTax - totals.otherLinesTotalTax}</NiceCurrency>
								</td>
								<td>
									<NiceCurrency>{vendorTotal - totals.grandTotal - totals.otherLinesGrandTotal}</NiceCurrency>
								</td>
							</tr>
						</tbody>
					</table>

					<table className="balancing">
						<thead>
							<tr>
								<th>Summary</th>
								<th>Subtotal</th>
								<th>Tax</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Before Adjustment</td>
								<td></td>
								<td>
									<NiceCurrency>{totals.total}</NiceCurrency>
								</td>
								<td>
									<NiceCurrency>{totals.totalTax}</NiceCurrency>
								</td>
								<td>
									<NiceCurrency>{totals.grandTotal}</NiceCurrency>
								</td>
							</tr>
							<tr>
								<td>Adjustment</td>
								<td></td>
								<td>
									<NiceCurrency>{totals.totalAdjustment}</NiceCurrency>
								</td>
								<td>
									<NiceCurrency>{totals.totalAdjustmentTax}</NiceCurrency>
								</td>
								<td>
									<NiceCurrency>{totals.grandTotalAdjustment}</NiceCurrency>
								</td>
							</tr>
							<tr>
								<td>After Adjustment</td>
								<td>
									<NiceCurrency>{totals.total + totals.totalAdjustment}</NiceCurrency>
								</td>
								<td>
									<NiceCurrency>{totals.totalTax + totals.totalAdjustmentTax}</NiceCurrency>
								</td>
								<td>
									<NiceCurrency>{totals.grandTotal + totals.grandTotalAdjustment}</NiceCurrency>
								</td>
							</tr>
						</tbody>
					</table>
				</FlexRow>

				{xeroPaymentInfo.length > 0 ? (
					<div className="flex-row" style={{ justifyContent: "flex-end" }}>
						<table className="xeroPayments">
							<thead>
								<tr>
									<th colspan="5" style={{ textAlign: "center" }}>
										Xero Payments
									</th>
								</tr>
								<tr>
									<th>Account</th>
									<th>Status</th>
									<th>Reference</th>
									<th>Date</th>
									<th>Amount</th>
								</tr>
							</thead>
							<tbody>
								{_.compact(
									_.map(xeroPaymentInfo, (payment, index) => {
										return (
											<tr key={index}>
												<td>{payment.account}</td>
												<td>{payment.status == "DELETED" ? <b>{payment.status}</b> : payment.status}</td>
												<td>{payment.reference}</td>
												<td>{payment.date}</td>
												<td>
													{payment.status == "DELETED" ? (
														<s>
															<NiceCurrency>{payment.amount}</NiceCurrency>
														</s>
													) : (
														<NiceCurrency>{payment.amount}</NiceCurrency>
													)}
												</td>
											</tr>
										);
									})
								)}
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td>Total:</td>
									<td>
										<NiceCurrency>{getXeroPaymentTotal()}</NiceCurrency>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				) : null}

				<Expandable minimizedContent={"Advanced Options"} open={false}>
					<Button onClick={() => startClearLines()}>Clear All Lines from AP</Button>
					<Button onClick={() => startCopy()}>Copy Lines from AP</Button>

					<Button onClick={() => doVoid()}>Void AP Invoice</Button>
					<AdvancedProcessEditors />
				</Expandable>

				<div className="clearStuff">
					<StyleStyle
						rules={`
    			    .clearStuff { flex-basis: 100%;}
    			    .grid { }
    		      `}
					/>
				</div>

			</BoundCommit>

			<pre>{raw}</pre>
		</div>
	);
}

const APLineTabular = ({ related, loadedLines, matches, order = ["id"], orderDirection = ["asc"] }) => {
	const boundMagic = useContext(DataContext);
	const [items, setItems] = useState([]);

	useEffect(() => {
		setItems(_.flatten(_.map(_.orderBy(loadedLines, order, orderDirection), (line) => {
			const item = { ...line.items[0] };
			item.paymentAdjustment = line.paymentAdjustment;
			item.totalMargin = line.totalMargin;
			if (item.paymentAdjustment == undefined) {
				item.paymentAdjustment = 0;
			}

			if (line.rawLine != null && line.rawLine["Activity Description"]) {
				item.vendorActivityDescription = line.rawLine["Activity Description"];
			}

			return item;
		})));
	}, [loadedLines]);

	const getTotalWithTax = (row) => {
		return calculateFinalTotal(row.extendedPrice, row.paymentAdjustment, true, row.taxId != undefined ? row.taxId.rate : 0, true);
	}

	function ServiceThing({ row }) {
		const [data, dataSet] = useData("service", row.service)
		const serviceFriendlyId = findServiceFriendlyId(row.service)

		if (dataSet.loading == true) return <span className="nowrap" title="Loading data...">{serviceFriendlyId}</span>
		else return <span className="nowrap" onClick={() => console.log(data)} title={"Material Description: " + data.longDescription + ", Frequency: " + data.scheduleDays + ", Quantity on Site: " + data.quantityOnSite}>{serviceFriendlyId}</span>;
	}

	const getRowClass = (row) => {
		if (row && row.errorsAndInconsistencies && row.errorsAndInconsistencies.length > 0 && row.lineItemTypeRelatedId) {
			if (APP.registeredBoundMagics.AP && APP.registeredBoundMagics.AP.to && APP.registeredBoundMagics.AP.to.lines) {
				const line = _.find(APP.registeredBoundMagics.AP.to.lines, ['id', row.lineItemTypeRelatedId]);
				if (line && !line.paymentAdjustmentNotes) {
					return " issue";
				}
			}
		}
		return "";
	}

	return <Tabular data={items} rowClass={getRowClass}>
		<TabularColumn data={(row) => <ServiceInfo related={related} displayField="site" row={row} />} title="Site" />
		<TabularColumn data={(row) => <ServiceInfo related={related} displayField="siteAddress" row={row} />} title="Address" />
		<TabularColumn data={(row) => <ServiceInfo related={related} row={row} />} title="Description" />
		<TabularColumn data={(row) => row.vendorActivity != undefined ? row.vendorActivity.activity : row.vendorActivityDescription} title="Vendor Activity" />
		<TabularColumn data={(row) => doDates(row.periodFrom, row.periodTo)} title="Date" />
		<TabularColumn data={(row) => <NiceNumber places={0} maxPlaces={6}>{row.quantity}</NiceNumber>} title="Quantity" />
		<TabularColumn data={(row) => <NiceNumber places={2} maxPlaces={6}>{row.unitPrice}</NiceNumber>} title="Unit Price" />
		<TabularColumn data={(row) => row.unitPricePer} title="Per" />
		<TabularColumn data={(row) => row.description} title="Reference" />
		<TabularColumn data={(row) => <NiceCurrency>{row.extendedPrice}</NiceCurrency>} title="Total" />
		<TabularColumn data={(row) => <NiceCurrency>{row.paymentAdjustment}</NiceCurrency>} title="Adjustment" />
		<TabularColumn data={(row) => row.taxId != undefined ? row.taxId.name : ""} title="Taxes" />
		<TabularColumn data={(row) => <NiceCurrency>{getTotalWithTax(row)}</NiceCurrency>} title="Total w/Tax" />
		<TabularColumn data={(row) => <IssueChecker data={row} />} title="Issues" />
		<TabularColumn data={(row) => <LinkToAR row={row} matches={matches} />} title="AR" style={{ width: "10em" }} />
		{/*<TabularColumn data={(row) => <LinkToAR row={row} matches={matches} show="margin" />} title="Margin" style={{ width: "10em" }} />*/}
	</Tabular>;
}

const APHighLevelTabular = ({ lines, openSite }) => {
	const boundMagic = useContext(DataContext);
	/** const [items, setItems] = useState([]);

	useEffect(()=>{
		setItems( _.flatten( _.map(_.orderBy(loadedLines, order, orderDirection), (line)=>{
			const item = {...line.items[0]};
			item.paymentAdjustment = line.paymentAdjustment;
			item.totalMargin = line.totalMargin;
			if(item.paymentAdjustment == undefined){
				item.paymentAdjustment = 0;
			}
			return item;
		}) ) );
	},[loadedLines]);

	const getTotalWithTax = (row) => {
		return calculateFinalTotal(row.extendedPrice, row.paymentAdjustment, true, row.taxId != undefined? row.taxId.rate : 0, true);
	}

	function ServiceThing({row}){
		const [data, dataSet] = useData("service", row.service)
		const serviceFriendlyId = findServiceFriendlyId(row.service)

		if (dataSet.loading == true) return <span className="nowrap" title="Loading data...">{serviceFriendlyId}</span>
		else return <span className="nowrap" onClick={()=>console.log(data)} title={"Material Description: " + data.longDescription + ", Frequency: " + data.scheduleDays + ", Quantity on Site: " + data.quantityOnSite}>{serviceFriendlyId}</span>;
	}
	  **/

	return <Tabular data={lines}>
		<TabularColumn data={(row) => <a id={"site-" + row.site} onClick={(e) => openSite(row, e)}>{row.status}</a>} title="Status" />
		<TabularColumn data={(row) => row.site + ": " + row.service_friendly_id} title="Service" />
		<TabularColumn data={(row) => row.service_description} title="Description" />
		<TabularColumn data={(row) => row.vendor_activity} title="Vendor Activity" />
		<TabularColumn data={(row) => doDates(row.period_from, row.period_to)} title="Date" />
		<TabularColumn data={(row) => <NiceNumber places={0} maxPlaces={6}>{row.quantity}</NiceNumber>} title="Quantity" />
		<TabularColumn data={(row) => <NiceNumber places={2} maxPlaces={6}>{row.unit_price}</NiceNumber>} title="Unit Price" />
		<TabularColumn data={(row) => row.unit_price_per} title="Per" />
		<TabularColumn data={(row) => row.item_description} title="Reference" />
		<TabularColumn data={(row) => <NiceCurrency>{row.extended_price}</NiceCurrency>} title="Total" />
		<TabularColumn data={(row) => <NiceCurrency>{row.payment_adjustment}</NiceCurrency>} title="Adjustment" />
		<TabularColumn data={(row) => row.expected_tax_code} title="Taxes" />
		<TabularColumn data={(row) => <NiceCurrency>{row.extended_price + row.expected_tax}</NiceCurrency>} title="Total w/Tax" />
		<TabularColumn data={(row) => "-"} title="Issues" />
		<TabularColumn data={(row) => "-"} title="AR" style={{ width: "10em" }} /> {/*<LinkToAR row={row} matches={matches}/>*/}
		{/*<TabularColumn data={(row) => "-"} title="Margin" style={{ width: "10em" }} /> {/*<LinkToAR row={row} matches={matches} show="margin"/>*/}
	</Tabular>;
}

const LinkToAR = (props) => {
	const boundMagic = useContext(DataContext);
	let relatedId = boundMagic.magicalGet("lineItemTypeRelatedId", 0);

	if (props.row != undefined) {
		relatedId = props.row.lineItemTypeRelatedId;
	}

	let match = _.find(props.matches, { id: relatedId });

	if (props.show == "margin") {
		return <div className="field" style={{ "display": "flex", "flexDirection": "row", "justifyContent": "space-between" }}>
			<NiceCurrency>{props.row.totalMargin}</NiceCurrency>
		</div>
	}

	if (match == null) {
		return <span>-</span>;
	}

	return <LinkButton target={"/ui/arinvoice/" + match.ar_invoice_id} title={match.activity} className="simple">
		<div className="field" style={{ "display": "flex", "flexDirection": "row", "justifyContent": "space-between", "color": "blue", "textDecorationLine": "underline" }}>
			<NiceCurrency>{match.after_extended_price}</NiceCurrency>
			<NiceCurrency>{match.total_savings}</NiceCurrency>
		</div>

	</LinkButton>;
}

const APLineGrid = ({ handleCellAction, addToRelated = null, handleRegisterGrid, related, saveLine, vendor, order = ["id"], orderDirection = ["asc"], matches, shouldDisplayRow = (row) => { return "contents"; }, customer }) => {
	const doOrders = [(row) => { return row.id > 0 ? row.id : (1000000 + (-1 * row.id)) }];

	const cellColourer = (bound) => {
		const row = bound.to;

		let colour = "white";
		_.forEach(row.items, (item) => {
			if (item && item.errorsAndInconsistencies && item.errorsAndInconsistencies.length > 0 && !row.paymentAdjustmentNotes) {
				colour = "#ff00003d";
			}
		});

		return colour;
	}

	return (
		<Grid
			shouldDisplayRow={shouldDisplayRow}
			registerGrid={handleRegisterGrid}
			boundData="lines"
			orderBy={doOrders}
			orderByDirection={orderDirection}
			key="ap_lines"
			gridIdentifier="ap_lines"
			appendable={{ text: "add line", add: handleCellAction, column: 0 }}
			disableHoleActions={true}
			cellColourer={cellColourer}
		>
			<Grid boundData="items">
				<GridItem title="Site" width="175" justify="left">
					<ServiceInfo addToRelated={addToRelated} related={related} displayField="site" invReqField = {true}/>
				</GridItem>
				<GridItem title="Address" width="175" justify="left">
					<ServiceInfo addToRelated={addToRelated} related={related} displayField="siteAddress" invReqField = {true}/>
				</GridItem>
				<GridItem title="Description" width="175" justify="left">
					<ServiceInfo addToRelated={addToRelated} related={related} invReqField = {true}/>
				</GridItem>
				<GridItem title="Vendor Activity">
					<ActivityThing field="vendorActivity.activity" edit={true} invReqField = {true}/>
				</GridItem>
				<GridItem title="Service Period From">
					{/*<DatePicker field="periodFrom" monthsShown={2} showPreviousMonths={true}/>*/}
					<DatePickerWithPeriodToUpdater invReqField = {true}/>
				</GridItem>
				<GridItem title="Service Period To">
					<DatePicker field="periodTo" monthsShown={2} showPreviousMonths={true} invReqField = {true}/>
				</GridItem>
				<GridItem title="Quantity">
					<InputDecimal field="quantity" name="Quantity" places={0} maxPlaces={6} invReqField = {true} parent="invoiceLine"/>
				</GridItem>
				<GridItem title="Unit Price">
					<InputDecimal field="unitPrice" name="Unit Price" places={2} maxPlaces={6} invReqField = {true} parent="invoiceLine"/>
				</GridItem>
				<GridItem title="Per">
					<LookupInputSelect what="lookup" field="unitPricePer" invReqField = {true}/>
				</GridItem>
				<GridItem title="Reference">
					<InputText field="description" name="Reference" />
				</GridItem>
				<GridItem title="Total">
					<TotalsFormula field="extendedPrice" />
				</GridItem>
				<GridItem title="Adjustment">
					<PaymentAdjustment />
				</GridItem>
				<GridItem title="Issues">
					<IssueChecker />
				</GridItem>
				<GridItem title="Taxes">
					<LookupInputSelect what="taxes" field="taxId" name="Tax" onChange={setTax} invReqField = {true}/>
				</GridItem>
				<GridItem title="Total w/Tax">
					<TotalsFormula field="extendedPrice" applyPaymentAdjustment={true} applyTax={true} />
				</GridItem>
				<GridItem title="AR">
					<LinkToAR matches={matches} />
				</GridItem>
			</Grid> 
			<GridItem title="Last Change">
				<Person field="lastModifiedBy" />
			</GridItem>
			<GridItem title="Actions">
				<RowActions
					action={(event, rowDetails, gridContext, action) => handleCellAction(event, rowDetails, gridContext, action)}
					saveLine={saveLine}
					vendor={vendor}
					addToRelated={addToRelated}
					customer={customer}
				/>
			</GridItem> 
		</Grid>
	);
}

const RowActions = ({ action, vendor, saveLine, addToRelated, customer }) => {
	const bound = useContext(DataContext); 

	const openLine = (boundTo, vendor, customer, r) => {
		APP.instance.createModal(<LineEdit customer={customer} row={boundTo} vendor={vendor} me={r} save={(a, b, c) => saveLine(a, b, c, r)} addToRelated={addToRelated} />, { modal_name: "Edit AP Line #" + boundTo.id });
	};

	return <div className="ap-line-row-actions">
		<EditLink openLine={(boundTo) => openLine(boundTo, vendor, customer)} />
		<span className="copy" onClick={(e) => action(e, { row: bound.to }, null, "copy")}><Icon icon="copy" size="2x" title="Copy (ctrl + shift + X)" /></span>
		<span className="new-line" onClick={(e) => action(e, { row: bound.to }, null, "new")}><Icon icon="plus-square" size="2x" title="New Line (enter)" /></span>
		<span className="clear" onClick={(e) => action(e, { row: bound.to }, null, "clear")}><Icon icon="empty-set" size="2x" title="Clear (ctrl + shift + backspace)" /></span>
		<span className="delete" onClick={(e) => action(e, { row: bound.to }, null, "delete")}><Icon icon="trash" size="2x" title="Delete (ctrl + shift + D)" /></span>
	</div>
}

export function EditLink({ openLine }) {
	const bound = useContext(DataContext);

	const handleClick = () => { openLine(bound.to) }

	return <Icon icon="pencil" onClick={handleClick} />;
}

export function ServiceInfo({ related, addToRelated = null, displayField = "longDescription", row = undefined, invReqField }) {
	const bound = useContext(DataContext);
	const [selectClientService, setSelectClientService] = useState(false);
	const [service, setService] = useState(null);
	const [site, setSite] = useState(null);
	const [customer, setCustomer] = useState(null);
	const [displayValue, setDisplayValue] = useState("");

	useEffect(() => {
		let containerName = service && service.containerName ? ": " + service.containerName : "";

		let display = null;

		if (displayField == "site" && site != null) {
			display = site.name;
			containerName = "";
		} else if (displayField == "siteAddress" && site != null) {
			display = site.address;
		} else if (service != undefined) {
			//display = service[displayField];            // this is dangerous because it could be an object and react will not like that
			display = _.toString(service[displayField]);  // this will give a "[object object]" string which is not good either but at least page will load
		} else if (site != undefined) {
			if (displayField != "longDescription") {
				display = site.friendlyId;
			} else {
				//not sure
			}
		} else {
			display = "No Service";

			if (row && row.lineItemTypeRelatedId > 0) {
				const apLine = _.find(bound.to.lines, { id: row.lineItemTypeRelatedId });

				if (apLine && apLine.rawLine) {

					const vendorInfo = displayField == "longDescription" ? apLine.rawLine["Service Description"] : apLine.rawLine["Site #"] + "/" + apLine.rawLine["Service #"] + ": " + apLine.rawLine["Vendor Site Address"] + ", " + apLine.rawLine["Vendor Site City"] + " " + apLine.rawLine["Vendor Site Province"];
					display = <span className="unmatchedVendorInfo">{vendorInfo}</span>;

				}
			}
		}

		setDisplayValue(display + " " + containerName);
	}, [site, service, customer]);

	useEffect(() => {
		const boundService = bound.magicalGet("service");
		const boundSite = bound.magicalGet("site");
		const boundCustomer = bound.magicalGet("customer");

		if (row != undefined) {
			updateStuff(row.service, row.site, row.customer);
		} else if (boundService != "" || boundSite != "" || boundCustomer != "") {
			updateStuff(boundService == "" ? null : boundService, boundSite == "" ? null : boundSite, boundCustomer == "" ? null : boundCustomer)
		} else {
			updateStuff(null, null, null);
		}

	}, [related, bound]);

	const updateStuff = (newService, newSite, newCustomer) => {
		if (newCustomer != undefined && _.isObject(newCustomer) && newCustomer.__is_shallow != true) {
			if (newCustomer.name != null) {
				setCustomer(newCustomer);
			} else {
				setCustomer(Util.findBaseEntity(related, newCustomer));
			}
		} else {
			if (newCustomer == undefined && _.isObject(bound.magicalGet("customer"))) {
				setCustomer(Util.findBaseEntity(related, bound.magicalGet("customer")));
			} else if (newCustomer) {
				if (_.isObject(newCustomer) && newCustomer.__is_shallow == true) {
					setCustomer(Util.findBaseEntity(related, newCustomer));
				} else {
					setCustomer(Util.findBaseEntity(related, { __type: "Customer", id: parseInt(newCustomer) }));
				}
			}
		}

		if (newSite != undefined && _.isObject(newSite) && newSite.__is_shallow != true) {
			if (newSite.name != null) {
				setSite(newSite);
			} else {
				setSite(Util.findBaseEntity(related, newSite));
			}
		} else {
			if (newSite == undefined && _.isObject(bound.magicalGet("site"))) {
				setSite(Util.findBaseEntity(related, bound.magicalGet("site")));
			} else if (newSite) {
				if (_.isObject(newSite) && newSite.__is_shallow == true) {
					setSite(Util.findBaseEntity(related, newSite));
				} else {
					setSite(Util.findBaseEntity(related, { __type: "Site", id: newSite == undefined ? parseInt(bound.magicalGet("site")) : parseInt(newSite) }));
				}
			}
		}

		if (newService != undefined && _.isObject(newService) && newService.__is_shallow != true) {
			setService(newService);
		} else {
			if (newService == undefined && _.isObject(bound.magicalGet("service"))) {
				setService(Util.findBaseEntity(related, bound.magicalGet("service")));
			} else {
				if (_.isObject(newService) && newService.__is_shallow == true) {
					setService(Util.findBaseEntity(related, newService));
				} else {
					setService(Util.findBaseEntity(related, { __type: "Service", id: newService ? parseInt(newService) : parseInt(bound.magicalGet("service")) }));
				}
			}
		}
	}

	const openClientSiteSelector = () => {
		const setter = (selectedService, selectedSite, selectedClient) => {
			APP.central.Service.fetch(selectedService.id).then((serviceR) => {
				APP.central.Customer.fetch(selectedClient.id).then((customerR) => {
					APP.central.Site.fetch(selectedSite.id).then((siteR) => {
						if (bound.magicalSet != undefined) {
							bound.magicalSet("service", serviceR.result.id);
							bound.magicalSet("site", siteR.result);
							bound.magicalSet("customer", customerR.result);
						}
						addToRelated([serviceR.result, siteR.result, customerR.result]);

						const serviceId = serviceR.result.id;
						const apLineId = bound.magicalGet("lineItemTypeRelatedId")
						APP.central.Snowman.assignServiceToAPLine(serviceId, apLineId)
					});
				});
			});

			return true;
		}

		APP.instance.createModal(<ClientSiteServiceSelector serviceCheckable={true} callback={(selectedService, selectedSite, selectedClient) => setter(selectedService, selectedSite, selectedClient)} />, { modal_name: "Add Service" });
	};

	const openService = () => {
		if (service != undefined) {
			APP.instance.createModal("/ui/services/edit/" + service.id, { modal_name: "Service #" + service.friendlyId });
		} else {
			openClientSiteSelector();
		}
	};

	let myTitle = null;
	if (customer && site) {
		myTitle = "Customer: " + customer.name + ", Site: " + site.name;
	}

	return <div>
			  {invReqField && (!displayValue || (displayValue && displayValue.length == 0) || (displayValue && displayValue.startsWith("No Service")))? <div className="service-info simple-link" style={{border: "1px solid red", borderRadius: "0.3rem", width:"160px"}} onClick={() => openService()} title={myTitle}>{displayValue}</div>
			               : <div className="service-info simple-link" onClick={() => openService()} title={myTitle}>{displayValue}</div>}
		   </div>;
}

ServiceInfo.defaultProps={ 
	invReqField: null		
}

export const ActivityThing = ({ field = "activity", show = "vendor", edit = false, invReqField }) => {
	const bound = useContext(DataContext);
	let title = "";
	let descriptor = bound.magicalGet("activity");

	if (show == "vendor" && bound.magicalGet("vendorActivity.activity")) {
		title = "WSC " + descriptor;
		descriptor = bound.magicalGet("vendorActivity.activity");
	} else {
		if (bound.magicalGet("vendorActivity.activity")) {
			title = "Vendor " + bound.magicalGet("vendorActivity.activity");
		}
	}

	if (title == "" && bound.parentMagic.magicalGet("rawLine") != null) {
		title = bound.parentMagic.magicalGet("rawLine")["Activity Description"];
	}

	if (bound.parentMagic == null) {
		return <em>Bad stuff</em>
	}

	if (edit) {
		const vendorId = bound.parentMagic.parentMagic.magicalGet("vendor.id");
		const parentVendorId =
			bound.parentMagic.parentMagic.magicalGet("vendor") && bound.parentMagic.parentMagic.magicalGet("vendor").parentVendor
				? bound.parentMagic.parentMagic.magicalGet("vendor").parentVendor.id
				: -1;

		const refreshVendorActivities = () => {
			_GLOBAL_DATA_CACHE['vendor_activity;lookup'].fetch();
		}

		const newVendorActivity = () => {
			APP.instance.createModal(
				<EditVendorActivity vendorActivity={{ __new: true }} />,
				{ vendor: vendorId },
				{ afterClose: () => refreshVendorActivities() }
			);
		};

		// old
		// filter={(r) => r.vendor && r.vendor.id == vendorId}
		return (
			<LookupInputSelect
				what="vendor_activity"
				filter={(r) => r.vendor && (r.vendor.id == vendorId || (parentVendorId == r.vendor.id))}
				display="activity"
				field={"vendorActivity"}
				name="Vendor Activity"
				store="object"
				appendable={true}
				handleAppendClicked={newVendorActivity}
				invReqField={invReqField}
				appendableLabel={"New Vendor Activity"}
				title={title}
			/>
		);
	}

	if (descriptor != null && descriptor > "") {
		return <span title={title}>{descriptor}</span>;
	}

	return <span title={""} className="none"></span>;
}

ActivityThing.defaultProps={ 
	invReqField: null		
}

export const LineThing = ({ row }) => {
	const bound = useContext(DataContext)	
	let title = ""
	//quantity, size, material, frequency
	return <span title={title}>{row.vendorActivity == null ? null : row.vendorActivity.activity}</span>
}

const doDates = (dateFrom, dateTo) => {
	const dateTitle = moment(dateFrom).format("YYYY-MM-DD") + " to " + moment(dateTo).format("YYYY-MM-DD")

	if (dateFrom !== dateTo) {
		return <span className="none" title={dateTitle}>{moment(dateFrom).format("MMM D") + " - " + moment(dateTo).format("D")}</span>
	}
	else if (dateFrom) {
		return <span className="none" title={dateTitle}>{moment(dateFrom).format("ddd MMM DD")}</span>;
	}
	return <span className="none"></span>;
}

export const ServiceDates = ({ from = undefined, to = undefined }) => {
	const bound = useContext(DataContext);
	const [dateFrom, setDateFrom] = useState(from);
	const [dateTo, setDateTo] = useState(to);

	useEffect(() => {
		bound.magicalState("periodFrom", setDateFrom);
		bound.magicalState("periodTo", setDateTo);
	}, [bound]);

	return doDates(dateFrom ? dateFrom : from, dateTo ? dateTo : to);
};

export const Person = ({ field }) => {
	const bound = useContext(DataContext);
	const [staff, setStaff] = useState(null);

	useEffect(() => {
		bound.magicalState(field, setStaff);
	}, [bound]);

	return <span>{staff ? <NiceStaffAvatar staff={staff} /> : null}</span>;
}

const ManualProcessEditors = () => {
	const boundMagic = useContext(DataContext);

	useEffect(() => {
		boundMagic.magicalState("vendorTotal", () => {
			updateVendorSubTotal();
		});

		boundMagic.magicalState("vendorTaxTotal", () => {
			updateVendorSubTotal();
		});
	}, [boundMagic]);

	const updateVendorSubTotal = () => {
		let newSubTotal = boundMagic.magicalGet("vendorTotal");
		if (newSubTotal == "") {
			newSubTotal = 0;
		}
		let vendorTaxes = boundMagic.magicalGet("vendorTaxTotal");
		if (vendorTaxes == "") {
			vendorTaxes = 0;
		}

		newSubTotal = newSubTotal - vendorTaxes;

		if (newSubTotal != boundMagic.magicalGet("vendorSubTotal")) {
			boundMagic.magicalSet("vendorSubTotal", newSubTotal.toFixed(4));
		}
	};

	let useOverrides = false;
	if (boundMagic.magicalGet("id") != "") {
		useOverrides =
			boundMagic.magicalGet("lines").length > 0 &&
			boundMagic.magicalGet("lines")[0].source != null &&
			boundMagic.magicalGet("lines")[0].source.indexOf("AP:") == 0;

		if (!useOverrides) {
			useOverrides = boundMagic.magicalGet("lines").length == 0 || boundMagic.magicalGet("lines")[0].source == undefined;
		}

		if (boundMagic.magicalGet("filterVendorSiteReferences") > "") {
			useOverrides = true;
		}
	}

	if (useOverrides) {
		return (
			<Fragment>
				<InputDecimal field="vendorTaxTotal" name="Vendor Tax Total" parent="invoiceLine" invReqField={true} />  
				<InputDecimal field="vendorTotal" name="Vendor Total" parent="invoiceLine" invReqField={true}/> 
				<FlexBreak />
			</Fragment>
		)
	} else {
		return <FlexBreak />;
	}

};

const AdvancedProcessEditors = () => {
	const boundMagic = useContext(DataContext);

	useEffect(() => {
		boundMagic.magicalState("vendorTotal", () => {
			updateVendorSubTotal();
		});

		boundMagic.magicalState("vendorTaxTotal", () => {
			updateVendorSubTotal();
		});
	}, [boundMagic]);

	const updateVendorSubTotal = () => {
		let newSubTotal = boundMagic.magicalGet("vendorTotal");
		if (newSubTotal == "") {
			newSubTotal = 0;
		}
		let vendorTaxes = boundMagic.magicalGet("vendorTaxTotal");
		if (vendorTaxes == "") {
			vendorTaxes = 0;
		}

		newSubTotal = newSubTotal - vendorTaxes;

		if (newSubTotal != boundMagic.magicalGet("vendorSubTotal")) {
			boundMagic.magicalSet("vendorSubTotal", newSubTotal.toFixed(4));
		}
	};

	const [showOverrides, setShowOverrides] = useState({ show: false });

	const doShowToggle = (e) => {
		setShowOverrides({ show: e });
	}

	return showOverrides.show ? (
		<>
			<Bound to={showOverrides}>
				<InputToggleSwitch
					field="show"
					name={"Adjust Tax & Total"}
					onChange={doShowToggle}
				/>
			</Bound>
			<InputDecimal field="vendorTaxTotal" name="Vendor Tax Total" /> 
			<InputDecimal field="vendorTotal" name="Vendor Total" />
			<FlexBreak />
		</>
	) : (
		<Bound to={showOverrides}>
			<InputToggleSwitch
				field="show"
				name={"Adjust Tax & Total"}
				onChange={doShowToggle}
			/>
		</Bound>
	);
};

