import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Bound, BoundDataContext, InputDecimal, InputMultiCheckbox, InputSelect, InputText, LookupInputSelect, PercentDisplay } from '@opidcore/components';
import { useData } from '@opidcore/hooks/WTF';
// import { DataContext } from '@opidcore/components/Bound';
import Util from '@opidcore/Util';
import FormulaEditor from '../../components/FormulaEditor';
import { CloseAndApply } from './EditLines';
import { Tabular, TabularColumn } from '../../../../../OpidCore/public/js/components';
import Notes from '../Notes';

const capitalizeWord = (word) => {
	return Util.capitalizeWord(word);
}

const getItemType = (item)=>{
	return _.map(item.magicalGet("lineItemType").split("_"), (chunk) => { return capitalizeWord(chunk) }).join(" ");
}

const RateBlock = () => {
	const [globalService, setGlobalService] = useState(null);
	const bound = useContext(BoundDataContext);

	const updateUnitPrice = (a, b, c, d) => {
		const gs = bound.magicalGet("calculator.globalService", {});
		if (gs != undefined) {
			if (gs.calculatedCharge != undefined) {
				bound.magicalSet("unitPrice", gs.calculatedCharge);
			}
		}
		setGlobalService(gs);
	}

	useEffect(() => {
		bound.magicalState("calculator.globalService", updateUnitPrice, null);
	}, []);

	return (
    <div className="rateBlock">
      <InputDecimal field="unitPrice" name="Rate" places={3} maxPlaces={4} disabled={globalService != null && globalService.id != null} />
      <PercentDisplay field="unitPrice" places={2} maxPlaces={2} disabled={true} className="rateBlockPercent" />
    </div>
  );
}

const SurchargeCalculator = ({ item, relatedItems, serviceData }) => {
	useEffect(() => {
		//APP.central.GlobalService.fetchParentsWithCurrentValues().then((r) => {

		APP.central.GlobalService.getCorrectGlobalServices(item.magicalGet("periodTo")).then((r) => {
			if (item.to && item.to.calculator && item.to.calculator.globalService && r.result && r.result.rows) {
				_.forEach(r.result.rows, (row) => {
					if ((row.id == item.to.calculator.globalService.id) || (row.parentId == item.to.calculator.globalService.id)) {
						item.magicalSet("calculator.globalService", row);
					}
				});
			}
		});

	}, []);

  /* Cesar commented out this code because it was not useful and was causing issues
  const onSelect = (option, isAdding) => {
		if(option.activity == null && isAdding == true){
			//added, but improper setup so it will cause issues until fixed
			APP.alert(option.vendorActivityDescription + " is missing a WSC activity. This will cause issues with surcharge calculations. Both a WSC and vendor activity are required.", "Warning");
		}
	}
  */

	let activity = <LookupInputSelect what="lookup" field="activity" name="Surcharge Activity" disabled={true} />;
	let isVendorActivity = false;

	if (item.magicalGet("lineItemType").indexOf("vendor") > -1) {
		const vendorActivityFetcher = () => {
			const vendor = serviceData.vendor;

			if (vendor == undefined) {
				return null;
			}

			return { vendor: vendor };
		}

		activity = <LookupInputSelect what="vendor_activity" fetch={vendorActivityFetcher} display="activity" field="vendorActivity" name="Surcharge Activity" disabled={true} />;

		isVendorActivity = true;
	}

	const itemType = getItemType(item);

	return (
		<div key="surcharge-calculator" className="surcharge-calculator">
			<h4>{itemType}</h4>
			<Bound to={item.to}>
				<div className="activity-select calculator-chunk">{activity}</div>
				<div className="surcharge-applied-to-list calculator-chunk">
					<h4>Applicable Activities:</h4>
					<InputMultiCheckbox field="calculator.appliesTo" optionsCollection={_.sortBy(relatedItems, (item) => Util.sortableFriendlyId(item.serviceFriendlyId))} optionKey="activity" storeAs="string" /*onSelect={(option, isAdding)=>onSelect(option, isAdding)}*/>
						{(row) => (
							<div className="tile-item">
								{isVendorActivity && row.vendorActivity ? row.vendorActivity.activity : row.activity}
								{row.service != serviceData.id ? " (" + row.serviceFriendlyId + ")" : ""}
							</div>
						)}
					</InputMultiCheckbox>
				</div>

				<div className="rate-block calculator-chunk">
					<LookupInputSelect
						what="globalservice"
						fetch={{ method: "getCorrectGlobalServices", date: item.magicalGet("periodTo") }}
						field="calculator.globalService"
						name="Surcharge"
						store="object"
					/>
					<RateBlock />
					<LookupInputSelect what="lookup:surchargeDuplicateOn" field="calculator.duplicatedOn" name="Apply to" />
				</div>

				<div className="tax-block calculator-chunk">
					<LookupInputSelect what="taxes" field="taxId" name="Tax" store="object" />
				</div>
			</Bound>
		</div>
	);
}

const MinimumMetricTonneCalculator = ({ item }) => {
	const itemType = getItemType(item);

	return <div key="min-metric-tonne-calculator" className="min-metric-tonne-calculator">
		<h4>{itemType}</h4>
		<Bound to={item.to}>
			<InputText field="calculator.minimumMetricTonnes" name="Min Tonne" />
		</Bound>
	</div>
}

const MarkupCalculator = ({ item }) => {
	const itemType = getItemType(item);

	const remove = ()=>{
		const a = item;
		debugger;
	}

	if (itemType != "Client Contract"){
		return null;
	}

	return <div key="min-metric-tonne-calculator" className="min-metric-tonne-calculator">
		<h4>{itemType}</h4>
		<Bound to={item.to}>
			<p>
				<em>This is used to pass a charge onto a customer but have the markup be applied to the total costs if the unit prices is unknown or calculated.</em>
			</p>
			<InputText field="calculator.extendedPriceMarkup" name="Price Markup" />
			<button type="button" className="btn" onClick={()=>remove()}>Remove Markup</button>;
		</Bound>
	</div>
}


const SplitServiceCalculator = ({ item }) => {
	const itemType = getItemType(item);
	const [splits, splitsDS] = useData("splits-" + item.magicalGet("id"), APP.central.ServiceContract.fetchRelatedSplitServices, {lineItemId: item.magicalGet("id")});

	useEffect( ()=>{
		splitsDS.fetch();
	},[]);
	
	if (itemType != "Vendor Contract"){
		return null;
	}

	return <div key="min-metric-tonne-calculator" className="min-metric-tonne-calculator">
		<h4>{itemType} Split</h4>
		<Bound to={item.to}>
			<strong>{item.to.unitPrice} {item.to.unitPricePer}</strong>
			<InputText field="calculator.splitPercent" name="Split Percent" />
		</Bound>

	{/* //select service.id as service_id, service.friendly_id, site."name", customer.name, li_split.id as vendor_item_split, lic.split_percent from line_item */}
		<Tabular data={splits}>
				<TabularColumn title="Service" data={(row)=>row.friendly_id}/>
				<TabularColumn title="Site" data={(row)=>row.site_name}/>
				<TabularColumn title="Customer" data={(row)=>row.customer_name}/>
				<TabularColumn title="Split" data={(row)=>row.split_percent}/>				
		</Tabular>
	</div>
}

const TieredPricingCalculator = ({ item }) => {
	const itemType = getItemType(item);
	return <div key="tiered-pricing-calculator" className="tiered-pricing-calculator">
		<h4>{itemType}</h4>
	</div>
}

const CommoditiesCalculator = ({ item }) => {
	const itemType = getItemType(item);
	const calculator = item.getBound("calculator");
	//const settings = useRef( {} );

	useEffect( ()=>{	
		const bound = calculator._ourMagic;

		if ( bound.magicalGet("settings", null) == null){
			bound.magicalSet("settings", {CONVERT_TO_CAD: true});
		}

		bound.magicalSet("settings._saveFull", true);
	}, [calculator]);

	const obmOptions = {"buffaloLow":"Buffalo Low","buffaloHigh":"Buffalo High","chicagoLow":"Chicago Low","chicagoHigh":"Chicago High","pacificNwLow":"Pacific NW Low","pacificNwHigh":"Pacific NW High"};

	if (item.magicalGet("lineItemType") == "client_contract"){
		obmOptions["vendorPrice"] = "Vendor 'Market Price'";
	}

	return <div key="commodities-calculator" className="commodities-calculator">
		<h4>{itemType}</h4>


		<Bound to={calculator} >
			<h2>Conversions</h2>

			<InputSelect field="settings.USE_OBM" name="OBM Rate" options={obmOptions}/>
			<InputSelect field="settings.CONVERT_TO_CAD" name="Convert to CAD?" options={{false: "No", true: "Yes"}}/>
			<InputSelect field="settings.CONVERT_TO_MT" name="Convert to MT?" options={{false: "No", true: "Yes"}}/>

			<InputDecimal field="settings.PERCENT_OF_OBM" name="% of OBM"/>
			<InputDecimal field="settings.PROCESSING_FEE" name="Processing Fee"/>
		</Bound>

		<Bound to={item.to} >
			<h2>Formula</h2>
			<FormulaEditor defaultFormula=""/>
		</Bound>
	</div>
}

const CalculatorNavBar = ({ links }) => {
	const [active, setActive] = useState("0");

	const setActiveAndClick = (i, e, l) => {
		setActive(i);
		if (l && l.props && l.props.onClick) {
			l.props.onClick(e);
		}
	};

	var navLinks = <div className='nav-items'>
		{_.map(links, (l, index) => {
			return <div key={"link" + index} className={"nav-item " + (index == active ? "nav-item-active" : "")} onMouseUp={(e) => setActiveAndClick(index, e, l)}>{l}</div>
		})}
	</div>;

	return <div className="sub-nav sub-nav-with-links">
		{navLinks}
	</div>;
}

const getLinks = (setter, options) => {
	const ret = [];
	ret.push(<div key="charges" onClick={() => setter("charges")}>Charges</div>);
	ret.push(<div key="commodities" onClick={() => setter("commodities")}>Commodities</div>);
	ret.push(<div key="tiered-pricing" onClick={() => setter("tieredPricing")}>Tiered Pricing</div>);
	ret.push(<div key="min-tonne" onClick={() => setter("minTonne")}>Minimum Tonnes</div>);
	ret.push(<div key="min-tonne" onClick={() => setter("markup")}>Markup</div>);
	if (options.showSplit){
		ret.push(<div key="split" onClick={() => setter("split")}>Split</div>);
	}
	return ret;
}

const ItemCalculator = ({ lineItemsBoundMagics, allLineItemMagics, serviceData, showSplit }) => {
	const [activeTab, setActiveTab] = useState("charges");
	const nav = CalculatorNavBar({ links: getLinks(setActiveTab, {showSplit: showSplit} ) });

	const tabs = { charges: [], minTonne: [], commodities: [], tieredPricing: [], "markup": [], "split": [] };

  tabs.commodities.push(
  <div style={{display: "flex", justifyContent: "flex-end", flexBasis: "100%"}}>
    <Notes model={{__type: "Commodity", id: serviceData.id}} />
  </div>)
	
	_.forEach(lineItemsBoundMagics, (boundItem) => {
		const lineRelatedItems = _.filter(allLineItemMagics, (aItem) => {
			const boundItemType = boundItem.magicalGet("lineItemType");
			let activityField = "activity";
			if(boundItemType == "vendor_contract"){
				activityField = "vendorActivityDescription";
			}
			if (boundItem.magicalGet("id") != aItem.magicalGet("id")
				&& aItem.magicalGet("lineItemType") == boundItemType && aItem.magicalGet(activityField).length > 0) {

				if (aItem.magicalGet("calculator") != '') {
					//check if already has item applied to it
					const appliesTo = aItem.magicalGet("calculator").appliesTo;

					if (appliesTo != null && appliesTo.indexOf(boundItem.magicalGet("activity")) > -1) {
						return false;
					}
				}

				return true;
			}

			return false;
		});

		const optionsCollection = _.map(lineRelatedItems, (i) => {
			return i.to;
		});

		tabs.charges.push(<SurchargeCalculator key={"sc" + boundItem.magicalGet("id")} item={boundItem} relatedItems={optionsCollection} serviceData={serviceData} />);
		tabs.commodities.push(<CommoditiesCalculator key={"c" + boundItem.magicalGet("id")} item={boundItem} />);
		tabs.tieredPricing.push(<TieredPricingCalculator key={"tp" + boundItem.magicalGet("id")} item={boundItem} />);
		tabs.minTonne.push(<MinimumMetricTonneCalculator key={"mmt" + boundItem.magicalGet("id")} item={boundItem} />);
		tabs.markup.push(<MarkupCalculator key={"markup" + boundItem.magicalGet("id")} item={boundItem} />);
		tabs.split.push(<SplitServiceCalculator key={"mmt" + boundItem.magicalGet("id")} item={boundItem} />);
	});

	return <div className="item-calculator" key="item-calculator">
		{nav}
		<div className="tab-container">
			{tabs[activeTab]}
		</div>
		<CloseAndApply/>
	</div>;
}

ItemCalculator.propTypes = {
	item: PropTypes.objectOf(PropTypes.any),
	parentLine: PropTypes.objectOf(PropTypes.any),
}

export default ItemCalculator;