import React, { useEffect, useState, useContext, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useData, useNavBar } from '@opidcore/hooks/WTF';
import { BoundCommit, Bound, InputText, DatePicker, InputCheckbox, Icon, FlexBreak, FlexRow, Button, InputMultiCheckbox, InputDecimal, ActionBar, InputAutoComplete, HoverElement, InputPercent, MagicalState, InputToggleSwitch, AccessControl, BoundDataContext } from '@opidcore/components';
import { Selection, Tabular, TabularColumn} from '@opidcore/components/Tabular';
import * as _ from 'lodash';

import { XeroLink, FileLink, SelectClientSiteService, SimpleMonthPicker, StaffSelect } from '../../components';
import LookupInputSelect from '@opidcore/components/LookupInputSelect';

import Grid, { GridItem } from '@opidcore/components/Grid';
import Util from '@opidcore/Util';
import { NiceNumber, NiceCurrency, NiceDate, NicePercent } from '../Nice.jsx';


import FormulaEditor from "../../components/FormulaEditor"
import { EditLink, Person, ServiceDates, ServiceInfo, TotalsFormula } from '../APInvoice/EditAPInvoice';
import { ToolbarContext } from '@opidcore/components/SaveToolbar';
import { withXero } from '../../components/XeroLink';
import moment from 'moment';
import Notes from '../Notes';
import Tasks from '../Tasks';
import { CompensationSelector } from '../../components/JasonBlocks';
import LinkButton from '../../components/LinkButton';
import { ReportTemplateLookupSelector } from '../Customer/EditClient';
import { BoundMagic, DataContext } from '@opidcore/components/Bound';
import ChangeLogs from '../ChangeLog/View';
//import { EndDatePicker } from '@opidcore/components/DatePicker';
import SimplePDFPreview from '../../components/SimplePDFPreview';
//import SiteEdit from '../Site/EditSite';
//import { Field } from '../../components/ViewLayouts';
import HoverContactList from '../../components/HoverContactList';
import { EditServicesWithTabs } from '../Service/EditService';
//import { Something } from '@opidcore/Customization';

const calculateFinalTotal = (initialValue, paymentAdjustment, applyPaymentAdjustment, tax, applyTax) => {
	let finalTotal = initialValue;

	if (applyPaymentAdjustment) {
		finalTotal += paymentAdjustment;
	}

	if (applyTax) {
		if (tax != undefined) {
			finalTotal = finalTotal * (1 + tax / 100);
		}
	}

	return finalTotal;
}

const CalculatorNavBar = ({ links }) => {
	const [active, setActive] = useState("0");

	const setActiveAndClick = (i, e, l) => {
		setActive(i);
		if (l && l.props && l.props.onClick) {
			l.props.onClick(e);
		}
	};

	var navLinks = <div className='nav-items'>
		{_.map(links, (l, index) => {
			return <div key={"link" + index} className={"nav-item " + (index == active ? "nav-item-active" : "")} onMouseUp={(e) => setActiveAndClick(index, e, l)}>{l}</div>
		})}
	</div>;

	return <div className="sub-nav sub-nav-with-links">
		{navLinks}
	</div>;
}

const getLinks = (setter) => {
	const ret = [];
	ret.push(<div key="surcharge" onClick={() => setter("surcharge")}>Surcharge Calculator</div>);

	return ret;
}

const RateBlock = () => {
	const [globalService, setGlobalService] = useState(null);
	const bound = useContext(DataContext);

	const updateUnitPrice = (a, b, c, d) => {
		const gs = bound.magicalGet("calculator.globalService", {});
		if (gs != undefined) {
			if (gs.calculatedCharge != undefined) {
				bound.magicalSet("unitPrice", gs.calculatedCharge);
			}
		}

		setGlobalService(gs);
	}

	useEffect(() => {
		bound.magicalState("calculator.globalService", updateUnitPrice, null);
	}, []);

	return <InputDecimal field="unitPrice" name="Rate" places={2} maxPlaces={6} disabled={globalService != null && globalService.id != null} />;
}

const SurchargeCalculator = ({ lineItem, relatedActivities }) => {
	let activity = <LookupInputSelect what="lookup" field="activity" name="Surcharge Activity" disabled={true} />;
	let isVendorActivity = false;

	if (lineItem.lineItemType != undefined && lineItem.lineItemType.indexOf("vendor") > -1) {
		const vendorActivityFetcher = () => {
			const vendor = lineItem.vendor;

			if (vendor == undefined) {
				return null;
			}

			return { vendor: vendor };
		}

		activity = <LookupInputSelect what="vendor_activity" fetch={vendorActivityFetcher} display="activity" field="vendorActivity" name="Surcharge Activity" disabled={true} />;

		isVendorActivity = true;
	}

	return <div key={"surcharge-calculator-" + lineItem.id} className="surcharge-calculator">
		<div className="activity-select">
			{activity}
		</div>
		<div className="surcharge-applied-to-list">
			<h4>Applicable Activities:</h4>
			<InputMultiCheckbox field="calculator.appliesTo" optionsCollection={_.sortBy(relatedActivities, (item) => Util.sortableFriendlyId(item.serviceFriendlyId))} optionKey="activity" storeAs="string">
				{(row) => <div className="tile-item">{isVendorActivity ? row.vendorActivity.activity : row.activity}</div>}
			</InputMultiCheckbox>
		</div>

		<div className="rate-block">
			<LookupInputSelect what="globalService" field="calculator.globalService" name="Surchage" store="object" />
			<RateBlock />
		</div>

		<FormulaEditor />
	</div>;
}

const LineItemCalculator = ({ lineItem, relatedActivities = [] }) => {
	const [activeTab, setActiveTab] = useState("surcharge");
	const nav = CalculatorNavBar({ links: getLinks(setActiveTab) });
	const tabs = { surcharge: undefined };

	tabs.surcharge = <SurchargeCalculator lineItem={lineItem} relatedActivities={relatedActivities} />;

	return <div key={"line-item-calculator-" + lineItem.id} className="line-item-calculator">
		<Bound to={lineItem}>
			<div className="item-calculator" key="item-calculator">
				{nav}
				<div className="tab-container">
					{tabs[activeTab]}
				</div>
			</div>
		</Bound>
	</div>
}

function ItemCalculatorIcon(props) {
	const lineBoundMagic = useContext(DataContext);
	const item = lineBoundMagic.getBound("itemAfter");

	const openItemCalculator = () => {
		if (item.activity == undefined || item.activity.trim().length == 0) {
			return;
		}
		const relatedActivities = _.map(props.allLines, (line) => {
			if (line.itemAfter == undefined || line.itemAfter.activity == undefined || line.itemAfter.activity.trim() == "") {
				return null;
			}

			return { activity: line.itemAfter.activity };
		});

		const modalProps = {
			lineItem: item,
			relatedActivities: _.uniqBy(_.compact(relatedActivities), "activity")
		}

		APP.instance.createModal(<LineItemCalculator {...modalProps} />, { modal_name: "Line Item Calculator" });
	}

	const iconProps = {
		title: "Item Calculator",
		icon: "calculator",
		size: "3x",
		color: item.calculator == undefined ? "#ccc" : undefined
	};

	if (item.activity == undefined || item.activity.trim().length == 0) {
		iconProps.className = "disabled";
		iconProps.color = "#ccc";
		iconProps.title = iconProps.title + " - Missing Activity";
	}

	return <div className="modal-link" onClick={openItemCalculator}>
		<Icon {...iconProps} />
	</div>;
}

function LineEdit(props) { //props.row is the invoice line
	const boundMagic = useContext(DataContext);
	const [showCreateBefore, setShowCreateBefore] = useState(false);
	const [showCreateAfter, setShowCreateAfter] = useState(false);

	useEffect(() => {
		boundMagic.magicalState("itemBefore", (itemBefore) => {
			if (itemBefore == null) {
				setShowCreateBefore(true);
			} else {
				setShowCreateBefore(false);
			}
		});

		boundMagic.magicalState("itemAfter", (itemAfter) => {
			if (itemAfter == null) {
				setShowCreateAfter(true);
			} else {
				setShowCreateAfter(false);
			}
		});
	}, [boundMagic]);

	const itemBefore = boundMagic.getBound("itemBefore");
	const itemAfter = boundMagic.getBound("itemAfter");

	const createBeforeItem = () => {
		boundMagic.magicalSet("itemBefore", { id: 0 });
	}

	const createAfterItem = () => {
		boundMagic.magicalSet("itemAfter", { id: 0 });
	}

	let customerField = "itemAfter.customer";
	if (itemAfter._ourMagic.magicalGet("customer", null) == null || itemAfter._ourMagic.magicalGet("customer", null) == 0) {
		customerField = "itemBefore.customer";
	}

	const invoice = boundMagic.getParentMagic().getBound();

	const customer = _.find(props.related, {__type: "Customer"});

	const updateRelated = (relatedSite, relatedService) => {
		props.applyNewRelated(relatedSite, relatedService);
	}

	return <div className="edit-ar-line">
		<h2>Line #{props.rowIndex + 1} Details</h2>
		<FlexBreak />
		<Bound to={invoice}>
			<SimpleMonthPicker field="billingPeriod" label="Billing Period" disabled={true} />
		</Bound>
		<InputAutoComplete field={customerField} name="Client" central={APP.central.Customer.autocomplete} disabled={true}>
			{(customer) => <div className="customer_option">{customer.displayValue}</div>}
		</InputAutoComplete>
		<InputPercent field="savingsPercentCharge" name="Savings %" />

		<DatePicker field="itemAfter.periodFrom" name="Service Period From" monthsShown={2} showPreviousMonths={true} />
		<DatePicker field="itemAfter.periodTo" name="Service Period To" monthsShown={2} showPreviousMonths={true} />

		<div className="line-item-container">
			<div className="line-item line-item-before">
				<h4>Baseline</h4>
				<div className="line-summary-block">
					{showCreateBefore ? <Button onClick={() => createBeforeItem()}>Create Item</Button> : null}
				</div>
				<LookupInputSelect what="lookup" field="itemBefore.activity" name="Activity" />
				<Bound to={itemBefore}>
					<SelectClientSiteService show={["site", "service"]} customer={customer}/>
					<ServiceLink field="itemBefore.serviceFriendlyId"/>
				</Bound>

				<InputText field="itemBefore.description" name="Description" />

				<InputText field="itemBefore.quantity" name="Quantity" />
				<InputText field="itemBefore.quantityMultiplier" name="Frequency (" />
				<InputText field="itemBefore.unitPrice" name="Unit Price" />
				<LookupInputSelect what="lookup" field="itemBefore.unitPricePer" name="Unit Price Per" />
				<InputText field="itemBefore.extendedPrice" name="Extended Price" />

				<InputText field="itemBefore.footnote" name="Invoice Footnote" />

			</div>

			<div className="line-item line-item-after">
				<h4>Current Billing</h4>
				<div className="line-summary-block">
					{showCreateAfter ? <Button onClick={() => createAfterItem()}>Create Item</Button> : null}
					<Bound to={boundMagic.to}>
						<span className="total-savings"><Icon icon="piggy-bank" title="Savings" /><CalculateSavingsRevenue show="savings" /></span>
						<span className="total-revenue"><Icon icon="money-bill-wave" title="Revenue" /><CalculateSavingsRevenue show="revenue" /></span>
					</Bound>
					<ItemCalculatorIcon allLines={props.allLines} />
				</div>
				<LookupInputSelect what="lookup" field="itemAfter.activity" name="Activity" />
				<Bound to={itemAfter}>
					<SelectClientSiteService show={["site", "service"]} customer={customer} onChange={(newSite, newService)=>updateRelated(newSite, newService)}/>
					<ServiceLink field="itemAfter.serviceFriendlyId"/>
				</Bound>
				<InputText field="itemAfter.description" name="Description" />

				<InputText field="itemAfter.quantity" name="Quantity" />
				<InputText field="itemAfter.quantityMultiplier" name="Frequency" />
				<InputText field="itemAfter.unitPrice" name="Unit Price" />
				<LookupInputSelect what="lookup" field="itemAfter.unitPricePer" name="Unit Price Per" />
				<InputText field="itemAfter.extendedPrice" name="Extended Price" />
				<LookupInputSelect what="taxes" field="itemAfter.taxId" name="Tax" store="object" />

				<InputToggleSwitch
					field="itemAfter.fixedBilling"
					name={(status) => {
						return status == undefined || status == false ? "Expected Charge" : "Expected Charge";
					}}
					icon="check-circle"
				/>

				<InputText field="itemAfter.footnote" name="Invoice Footnote" />
			</div>
		</div>

	</div>
}

const ServiceLink = ({field}) => {
	const boundMagic = useContext(BoundDataContext);
	const [service, setService] = useState(null);

	useEffect(()=>{
		boundMagic.magicalState("service", (val)=>{
			setService(val);
		})
	});

	const openService = () => {
		const fieldSplit = field.split(".");
		const label = APP.registeredBoundMagics["AR"].magicalGet("lines")[0][fieldSplit[0]][fieldSplit[1]];
		APP.instance.createModal("/ui/services/edit/" + service, { modal_name: "Service #" + label });
	}

	return <Icon icon="external-link" onClick={()=>openService()} />;
}

const CalculateSavingsRevenue = ({ show }) => {
	const bound = useContext(DataContext);
	const [savingsPercentCharge, setSavingsPercentCharge] = useState(null);
	const [totalSavings, setTotalSavings] = useState(0);
	const [afterPrice, setAfterPrice] = useState(0);
	const [beforePrice, setBeforePrice] = useState(0);

	useEffect(() => {
		bound.magicalState("savingsPercentCharge", setSavingsPercentCharge);

		if (bound.getBound("itemAfter")) {
			bound.getBound("itemAfter")._ourMagic.magicalState("extendedPrice", setAfterPrice);
		}
		if (bound.getBound("itemBefore")) {
			bound.getBound("itemBefore")._ourMagic.magicalState("extendedPrice", setBeforePrice);
		}

	}, [bound]);

	useEffect(() => {
		setTotalSavings(beforePrice - afterPrice);
	}, [beforePrice, afterPrice]);

	let res = totalSavings;
	let content = undefined;

	if (show == "savings") {
		content = <NiceCurrency>{res}</NiceCurrency>;
		return <span className="savings-revenue">{content}</span>;
	}

	if (res != 0 && savingsPercentCharge != undefined && savingsPercentCharge != "") {
		const percent = parseFloat(savingsPercentCharge) / 100;

		res = res * percent;
		content = <><NiceCurrency>{res}</NiceCurrency> @ {savingsPercentCharge}%</>;
	} else {
		res = 0.00;
		content = <NiceCurrency>{res}</NiceCurrency>;
	}

	return <span className="savings-revenue">{content}</span>;
}



export function ToolbarStuff({ bound, refreshInvoice, extra, receivedByContacts }) {
	const toolbarCtx = useContext(ToolbarContext);
	const [postingToXero, setPostingToXero] = useState(false);
	const xeroButtonText = useRef("Post To Xero");
	let boundMagic = useContext(DataContext);
	const [postAvailable, setPostAvailable] = useState(false);
	const [voidAvailable, setVoidAvailable] = useState(false);
	const [disablePostToXero, setDisablePostToXero] = useState(false)

	useEffect(() => {
		if (bound && bound._myMagic) {
			bound._myMagic.magicalState("xeroUUID", (a) => {
				if (a && a.length > 0) {
					xeroButtonText.current = "Posted";
					setPostAvailable(false);
					//setPostAvailable(true);
					setVoidAvailable(true);
				} else {
					xeroButtonText.current = "Post To Xero";
					setPostAvailable(true);
					setVoidAvailable(false);
				}
			});
		}
	}, [bound]);

	const doApprove = () => {
		if (!disablePostToXero) {
			setDisablePostToXero(true)

			const wtfMagic = bound._myMagic;
			let okToContinue = true;
			let message = "";

			/*
		if (extra && extra.combinedInvoices) {
				const stillIncomplete = extra.combinedInvoices.filter((i) => i.ar_id != wtfMagic.magicalGet("id") && ['approved', 'complete'].indexOf(i.ar_status) == -1);
				const details = _.map(_.groupBy(stillIncomplete, 'ar_status'), (a) => { return a.length + " " + a[0].ar_status }).join(', ')
				if (details.length > 0) {
					message = "You have combined invoices with incorrect statuses (" + details + ").  If you post to Xero you may have partially billed this client.";
					okToContinue = false;
				}
	
			}
		*/

			let warningCount = 0;
			if (extra && extra.missingServices && extra.missingServices.length > 0) {
				warningCount++;
				message +=
					warningCount + ") There are " +
					extra.missingServices.length +
					" missing services for this client. If you post to Xero you may have partially billed this client.";
				okToContinue = false;
			}

			if (receivedByContacts.length <= 0) {
				warningCount++;
				message +=
					warningCount + ") There are no Contacts set to receive this invoice.";
				okToContinue = false;
			}

			if (okToContinue) {
				doApproveCheck();
			} else {
				message += " Continue?";
				APP.instance.openConfirmDialog(message, "Xero Posting Warning", (result) => {
					if (result) {
						doApproveCheck();
					} else {
						setDisablePostToXero(false)
					}
				});
			}
		} else {
			alert("Already posting to Xero.")
		}

	}

	const doApproveCheck = () => {
		const wtfMagic = bound._myMagic;

		wtfMagic.magicalSet("status", "approved");
		wtfMagic.magicalGet("invoicePreparer", APP.getWebSession().userId);

		const args = {};
		args.thing = wtfMagic.to;

		const invId = wtfMagic.to.id;

		if (extra && extra.combinedInvoices) {
			const stillIncomplete = extra.combinedInvoices.filter((i) => i.ar_id != wtfMagic.magicalGet("id") && ['missing', 'approved', 'complete'].indexOf(i.ar_status) == -1);
			const details = _.map(_.groupBy(stillIncomplete, 'ar_status'), (a) => { return a.length + " " + a[0].ar_status }).join(', ')
			if (details.length > 0) {
				alert(details)
			}
		}
				/*APP.instance.openConfirmDialog("Approve related ARs?", "Approve", (r) => {
					if (r) {
						APP.central.ARInvoice.approveRelated(wtfMagic.magicalGet("id")).then((r) => {
							withXero(() => {
								//might need to move this to the queue way ( ARInvoice.flaggedForExport() )
								toolbarCtx.save().then(() => {
									APP.central.Xero.sync(args).then((r) => {
										if (r.result != null && r.result.xeroUUID) {
											wtfMagic.magicalSet("xeroUUID", r.result.xeroUUID);
											wtfMagic.magicalSet("date", moment().format("yyyy-mm-dd"));
					
											const created = r.result;
					
											if (created.action) {
												APP.alert(created.action);
					
												//Refresh the data from the server
												refreshInvoice(invId);
												setPostingToXero(false);
											}
										} else {
											debugger;
											APP.alert("Invoice was not created - " + r.result.status);
											setPostingToXero(false);
										}
									});
								});
							});
						})
					} else {
						alert("Combined invoice cannot be posted without approving all related invoices.")
					}
				})
				alert(details);
			} else { 
				withXero(() => {
					//might need to move this to the queue way ( ARInvoice.flaggedForExport() )
					toolbarCtx.save().then(() => {
						APP.central.Xero.sync(args).then((r) => {
							if (r.result != null && r.result.xeroUUID) {
								wtfMagic.magicalSet("xeroUUID", r.result.xeroUUID);
								wtfMagic.magicalSet("date", moment().format("yyyy-mm-dd"));
		
								const created = r.result;
		
								if (created.action) {
									APP.alert(created.action);
		
									//Refresh the data from the server
									refreshInvoice(invId);
									setPostingToXero(false);
								}
							} else {
								debugger;
								APP.alert("Invoice was not created - " + r.result.status);
								setPostingToXero(false);
							}
						});
					});
				});
			}
		} else */ withXero(() => {
			//might need to move this to the queue way ( ARInvoice.flaggedForExport() )
			toolbarCtx.save().then(() => {
				APP.central.Xero.sync(args).then((r) => {
					if (r.result != null && r.result.xeroUUID) {
						wtfMagic.magicalSet("xeroUUID", r.result.xeroUUID);
						wtfMagic.magicalSet("date", moment().format("yyyy-mm-dd"));

						const created = r.result;

						if (created.action) {
							APP.alert(created.action);

							//Refresh the data from the server
							refreshInvoice(invId);
							setPostingToXero(false);
						}
					} else {
						debugger;
						APP.alert("Invoice was not created - " + r.result.status);
						setPostingToXero(false);
					}
				});
			});
		});
	};

	const doVoid = () => {
		const wtfMagic = bound._myMagic;

		APP.instance.openConfirmDialog("Are you sure you want to VOID this invoice in Xero?", "Confirm VOID", (r) => {
			if (r) {
				const invId = wtfMagic.to.id;

				APP.central.Xero.voidARInvoice(boundMagic.to).then((r) => {
					refreshInvoice(invId);
				});
			}
		});

	}

	const fixMargins = () => {
		const magic = bound._myMagic;
		APP.instance.openConfirmDialog("Fixing the margins will automatically reload the page; you will lose any unsaved work.", "Fix Margins", (r) => {
			if (r) {
				APP.central.Medusa.CalculateMarginsFromAR(magic.to.id).then((r) => {
					window.location.reload(true);
				});
			} else {
				console.log("Didn't fix margins.")
			}
		})
	}

	const viewMargins = () => {
		APP.instance.createModal(<ARMarginList id={bound._myMagic.to.id} />)
	}

	const ARMarginList = (props) => {
		const [data, setData] = useState(null);

		useEffect(() => {
			APP.central.ARInvoice.getARMarginData(props.id).then((r) => {
				setData(r.result.rows)
			})
		}, [])
		
		const openInvoiceLinks = (row) => {
			APP.instance.createModal(<InvoiceLinks row={row} />)
		}
		
		const InvoiceLinks = (props) => {
			console.log(props.row)
			return <div>
				<h1>AP Invoices</h1>
				{props.row.ap_ids.split(", ").map((id) => {
					return <div><Link to={"/ui/apinvoice/" + id}>AP {id}</Link></div>
				})}
				<h1>AR Invoices</h1>
				{props.row.ar_ids.split(", ").map((id) => {
					return <div><Link to={"/ui/arinvoice/" + id}>AR {id}</Link></div>
				})}
			</div>
		}
		
		return <div>
			<h1>Margin Data</h1>
			<Tabular data={data}>
			<TabularColumn title="Customer" data={(row) => <Link to={"/ui/clients/" + row.customer_friendly_id}>{row.customer_name}</Link>} />
                <TabularColumn title="Industry" data={(row) => row.environmental_industry} />
                <TabularColumn title="Site" data={(row) => <Link to={"/ui/sites/detail/" + row.site_friendly_id}>{row.site_name}</Link>} />
                <TabularColumn title="Service" data={(row) => <Link to={"/ui/services/edit/" + row.service_id}>{row.service_friendly_id}</Link>} />
                <TabularColumn title="Manager" data={(row) => row.regional_account_manager} />
                <TabularColumn title="Billing Period" data={(row) => row.billing_period} />
                <TabularColumn title="AR Total" data={(row) => <NiceCurrency roundedPlaces={0}>{row.ar_total}</NiceCurrency>} />
                <TabularColumn title="AP Total" data={(row) => <NiceCurrency roundedPlaces={0}>{row.ap_total}</NiceCurrency>} />
                <TabularColumn title="Margin" data={(row) => <NiceCurrency roundedPlaces={0}>{row.margin}</NiceCurrency>} />
                <TabularColumn title="Margin %" data={(row) => <NicePercent places={0}>{row.margin_percentage}</NicePercent>} />
                <TabularColumn title="Invoices" data={(row) =>
                    <Icon onClick={() => openInvoiceLinks(row)} icon="file-invoice-dollar"/>
                }/>
			</Tabular>
				
		</div>
	}

	return (
		<>
			{postAvailable ? (
				postingToXero ? (
					<Button>Posting...</Button>
				) : (
					<Button onClick={() => doApprove()}>{xeroButtonText.current}</Button>
				)
			) : (
				<Button type="big-button btn btn-lg disabled" onClick={(e) => { if (e.shiftKey) { doApprove() } }}>{xeroButtonText.current}</Button>
			)}
			{voidAvailable ? <Button onClick={() => doVoid()}>Void</Button> : null}
			<Button onClick={() => fixMargins()}>Fix Margins</Button>
			<Button onClick={() => viewMargins()}>View Margins</Button>
			<HoverContactList client={boundMagic.magicalGet("customer", {})} />
			<InvoiceClientContactDetails receivedByContacts={receivedByContacts} customerId={boundMagic.magicalGet("customer").id} invoiceId={boundMagic.magicalGet("id")} />
			<OutboundMessages extra={extra} />
		</>
	);
}

export default function ARInvoiceEdit(props) {	
	const nav = useNavBar("Accounts Receivable Invoice"); 
	const [inv, setInv] = useState({});

	const [refresh, setRefresh] = useState(0);
	const [raw, setRaw] = useState("");
	const [data, dataSet] = useData("customer", parseInt(props.match.params.id));
	const [boundRef, setBoundRef] = useState(null);
	const [related, setRelated] = useState([]);
	const [extra, setExtra] = useState([]);
	const [hasFile, setHasFile] = useState(null);
	const [editingLines, setEditingLines] = useState(false);
	const gridRef = useRef();
	const [totals, setTotals] = useState({ invoiceSubTotal: 0, totalTax: 0, totalSavings: 0, totalMargins: 0, totalConverted: 0, currency: "CAD"});
	const [showSavings, setShowSavings] = useState(false);
	const [showBlendedCrap, setShowBlendedCrap] = useState(false);
	const [columnsToHide, setColumnsToHide] = useState({});
	const [currentReportTemplate, setCurrentReportTemplate] = useState(null);
	const pressed = useRef({});
	const [voidAvailable, setVoidAvailable] = useState(false);
	const [adjustedFromInv, setAdjustedFromInv] = useState(null);
	const [arPosted, setArPosted] = useState(false);

	const [sitesOnInv, setSitesOnInv] = useState([]);
	const [receivedByContacts, setReceivedByContacts] = useState([]);

	const [invoiceTiming, setInvoiceTiming] = useState(null)

	useEffect(() => {
		if (boundRef != null && boundRef.current._myMagic != undefined) {
			boundRef.current._myMagic.magicalState("compensationType", (compType) => {
				if (compType != undefined && typeof compType == "object") {
					let newValue = false;
					if (compType.name == "Cost +") {
						newValue = true;
					}

					const newColumnsToHide = { ...columnsToHide };
					newColumnsToHide[compType.name] = newValue;
					setColumnsToHide(newColumnsToHide);
				}
			});
			boundRef.current._myMagic.magicalState("xeroUUID", (a) => {
				if (a && a.length > 0) {
					setVoidAvailable(true);
					setArPosted(true);
				} else {
					setVoidAvailable(false);
					setArPosted(false);
				}
			});
		}
	}, [boundRef])

	useEffect(() => {
		if (data != null) {
			console.log(data) 
			APP.central.Customer.fetchInvoiceTimingFromAR(Number(props.match.params.id)).then((r) => {
				if (r.result != null) {
					setInvoiceTiming(r.result);
				}
			})
		}
		
		
	}, [])

	useEffect(() => {
		updateInvoiceTotals();

		if (inv.status === null || inv.status == "") { //it might not be loaded, in which case there is no status field
			setEditingLines(true);
		}
	}, [inv, boundRef]);

	useEffect(() => {
		const invoiceId = parseInt(props.match.params.id);
		APP.getState("handleSocket", (thing) => {
			
			if (thing.object && thing.object.id) {
				if (thing.object.__type == 'File' && thing.object.relatedId == invoiceId) {
					setHasFile(thing.object);
				}
			}
		});
	}, []);

	useEffect(() => {
		if (inv && inv.lines && inv.lines[0] && inv.lines[0].source && inv.lines[0].source.split(":")[0] == "AR-ADJUST") {
			const originalInv = inv.lines[0].source.split(":")[1];
			setAdjustedFromInv(originalInv);
		} else {
			setAdjustedFromInv(null);
		}

		if(inv && inv.id) {
			APP.central.Site.fetchInvoiceSiteClientContacts(inv.id).then((r) => {
				if(r.result && r.result.rows.length > 0) {
				setReceivedByContacts(r.result.rows); 
				}
			});
		}
		
    
	}, [inv])

	

	const addToRelated = (newThings) => {
		const newRelated = related.slice();
		if (_.isArray(newThings)) {
			_.forEach(newThings, (thing) => {
				newRelated.push(thing);
			});
		} else {
			newRelated.push(newThings);
		}

		setRelated(newRelated);
	};

	const updateRenumeration = () => {
		const bound = APP.registeredBoundMagics["AR"];
		if (bound == null) {
			return;
		}

		const compType = bound.magicalGet("compensationType");
		setShowSavings(compType.savings);

		setShowBlendedCrap(bound.magicalGet("compensationType").name == "Blended Savings");

	};

	const refreshInvoice = (invId) => {
		APP.central.ARInvoice.getRelated(invId).then((r) => {
			r.result.entity.apLink = r.result.extras.matches.length > 0 ? r.result.extras.matches[0].ap_invoice_id : null;
			for (var i = 0; i < r.result.entity.lines.length; i++) {
				if (r.result.entity.lines[i].itemAfter != null) {
					r.result.entity.lines[i].itemAfter.apLink = r.result.entity.lines[i].relatedAP;
					r.result.entity.lines[i].itemAfter.totalMargin = r.result.entity.lines[i].totalMargin;
				} else {
					//strange
				}
			}
			
			setInv(r.result.entity);
			setRelated(r.result.related);
			setExtra(r.result.extras);

			setSitesOnInv(
				_.compact(
					_.map(r.result.related, (rel) => {
						if (rel && rel.__type && rel.__type == "Site") {
							return rel;
						} else {
							return null;
						}
					})
				)
			);

		});

		APP.registeredBoundMagics["AR"].magicalState("clientType", updateRenumeration);
		APP.registeredBoundMagics["AR"].magicalState("compensationType", updateRenumeration);
	};

	const paramsInvId = parseInt(props.match.params.id);
	useEffect(() => {

		refreshInvoice(paramsInvId);

	}, [paramsInvId]);

	useEffect(() => {
		if (data != undefined && data.length > 0) {
			const links = [
				<Link to={"/ui/customers/" + props.match.params.id}>Client</Link>
			];

			nav.update(data[0].name, links);
		}
	}, [data]);

	const saveLine = (a, b, c) => {
		const them = c;

		const newInv = JSON.parse(APP.stringify(c.parentMagic ? c.parentMagic.to : c._ourMagic.parentMagic.to));

		APP.central.ARInvoice.prepLine(them).then((r) => {
			if (r.result != null) {
				const rowIdx = _.findIndex(newInv.lines, (l) => l.id == r.result.id);
				if (rowIdx == null) {
					newInv.lines.push(r.result);
				} else {
					newInv.lines[rowIdx] = r.result;
				}
			}
			setInv(newInv);
			setRefresh(refresh + 1);
		});
	}

	const applyNewRelated = (newSite, newService)=>{
		const newRelated = [];
		if(newSite != undefined){
			//add the related sites
			newRelated.push(newSite);
		}
		if(newService != undefined){
			//add the related services
			newRelated.push(newService);
		}

		if(newRelated.length > 0){
			setRelated(_.uniqBy(_.filter(_.union(newRelated, related), (r)=>r != null), (a)=>a.__type + "|" + a.id));
		}
	}

	const openLine = (line) => {
		APP.instance.createModal(<BoundCommit initialValue={line} commit={saveLine} saveVerb="Apply">
			<LineEdit addToRelated={addToRelated} applyNewRelated={(newSite, newService)=>applyNewRelated(newSite, newService)} related={related} row={line} allLines={inv.lines} rowIndex={line._keyPathPos} />
		</BoundCommit>, { modal_name: "AR Line #" + (line._keyPathPos + 1) });
	};

	const orderBy = ["id"];
	const orderByDirection = ["desc"];

	const handleCellAction = (event, rowDetails, gridContext, action) => {
		if(rowDetails == null || action == null){
			return;
		}
		let newItemBefore = { id: 0 };
		let newItemAfter = { id: 0 };
		let append = false;

		let beforeId = 0;
		let afterId = 0;
		let service = null;
		let site = null;
		let customer = null;
		let vendor = null;
		//ian should know

		if (rowDetails.row != undefined) {


			if (rowDetails.row.itemAfter != undefined) {
				const theItemAfterItem = rowDetails.row._ourMagic.magicalGet('itemAfter');

				afterId = theItemAfterItem.id;

				service = theItemAfterItem.service;
				site = theItemAfterItem.site;
				customer = theItemAfterItem.customer;
				vendor = theItemAfterItem.vendor;
			} else if (rowDetails.row.itemBefore != undefined) {
				const theItemBeforeItem = rowDetails.row._ourMagic.magicalGet('itemBefore');
				beforeId = theItemBeforeItem.id;

				service = theItemBeforeItem.service;
				site = theItemBeforeItem.site;
				customer = theItemBeforeItem.customer;
				vendor = theItemBeforeItem.vendor;
			}
		}

		if (rowDetails.row == undefined) {
			append = true;
		} else {
			if (action == "delete") {
				APP.instance.openConfirmDialog("Make sure to save changes on other lines before this delete. Do you want to continue?", "Deleting is permanent. Unsaved data of the lines will be lost.", (res) => {
					if (res == true) {
						if (rowDetails.row.id <= 0) {
							const forceUpdate = true;
							boundRef.current._myMagic.magicalSplice(rowDetails.row._keyPath, rowDetails.row._keyPathPos, forceUpdate); 
							setInv(boundRef.current._myMagic.to);
						} else {
							APP.central.ARInvoice.deleteLine(rowDetails.row.id).then((r) => {
								setInv(r.result);
							});							
						}
					}
				});
				return;
			} else if (action == "clear") {
				newItemBefore.id = beforeId;
				newItemBefore.service = service;
				newItemBefore.site = site;
				newItemBefore.customer = customer;
				newItemBefore.vendor = vendor;

				newItemAfter.id = afterId;
				newItemAfter.service = service;
				newItemAfter.site = site;
				newItemAfter.customer = customer;
				newItemAfter.vendor = vendor;

				//rowDetails.row.itemBefore = newItemBefore;
				//rowDetails.row.itemAfter = newItemAfter;

				boundRef.current._myMagic.getBound("lines", rowDetails.row._keyPathPos)._ourMagic.magicalSet("itemAfter", newItemAfter);
				boundRef.current._myMagic.getBound("lines", rowDetails.row._keyPathPos)._ourMagic.magicalSet("itemBefore", newItemBefore);
				setInv(boundRef.current._myMagic.to);
				return;
			}

			if (action == "copy") {
				newItemBefore = { ...rowDetails.row.itemBefore, ...newItemBefore };
				newItemAfter = { ...rowDetails.row.itemAfter, ...newItemAfter };

				delete newItemBefore._keyPathPos;
				delete newItemBefore._ourMagic;
				delete newItemBefore.lineItemType;
				delete newItemBefore.lineItemTypeRelatedId;

				delete newItemAfter._keyPathPos;
				delete newItemAfter._ourMagic;
				delete newItemAfter.lineItemType;
				delete newItemAfter.lineItemTypeRelatedId;

				append = true;
			} else {
				newItemBefore.service = service;
				newItemBefore.site = site;
				newItemBefore.customer = customer;
				newItemBefore.vendor = vendor;

				newItemAfter.service = service;
				newItemAfter.site = site;
				newItemAfter.customer = customer;
				newItemAfter.vendor = vendor;

				append = true;
			}
		}

		if (append == true) {
			let currentSize = boundRef.current._myMagic.magicalGet("lines").length;
			currentSize++;
			const newRow = { id: -1 * currentSize, itemAfter: newItemAfter, itemBefore: newItemBefore, __type: "ARInvoiceLine", source: "AR:" + inv.id };

			boundRef.current._myMagic.magicalAppend("lines", newRow); // orderBy, orderByDirection);
			setInv(boundRef.current._myMagic.to);
			return false;
		}
	};
    //Not sure if these methods are functional !!!
	const gridHotKeyDown = (e) => {
		if (e.key == "Control") {
			if (pressed.current[e.key] != undefined) {
				const diff = moment().diff(pressed.current[e.key]);

				if (diff > 2000) {
					//console.log("TODO Popup commands!");
				}

				return;
			}

			pressed.current[e.key] = moment();
		}
	}

	const gridHotKeyUp = (e, row, col, props) => {
		let handled = false;
		let action = "";

		if (e.key == "Enter") {
			handled = true;
			action = "new";
		} else if (e.ctrlKey == true && e.shiftKey == true) {
			if (e.key == " ") {
				handled = true;
				action = "copy";
			} else if (e.key == "Backspace") {
				handled = true;
				action = "clear";
			} else if (e.key == "D") {
				handled = true;
				action = "delete";
			}
		}

		if (handled == true) {			
			handleCellAction(e, props.ctx.findWhatIsAt(props.gridRow, props.gridCol), null, action);
		}

		return handled;
	}

	const updateInvoiceTotals = () => {
		if (boundRef != undefined && boundRef.current != null && boundRef.current._myMagic != undefined) {
			let total = 0;
			let totalTax = 0;
			let totalSavings = 0;
			let totalMargins = 0;
			let totalConverted = 0;
			let currency = boundRef.current._myMagic.magicalGet("currencyCode").length != 3 ? "CAD" : boundRef.current._myMagic.magicalGet("currencyCode")

			_.each(boundRef.current._myMagic.magicalGet("lines", []), (l) => {
				if (l.__type == "ARInvoiceLine") {
					const lineTotal = l.itemAfter ? l.itemAfter.extendedPrice : 0;
					const tax = l.itemAfter && l.itemAfter.taxId ? l.itemAfter.taxId.rate : 0;
					const totalMargin = l.totalMargin ? l.totalMargin : 0;

					total += lineTotal;
					totalTax += (lineTotal * (tax / 100));

					totalSavings += l.totalSavings;

					totalMargins += totalMargin;
				}
			});

			if (boundRef.current._myMagic.magicalGet("currencyRate") != null && boundRef.current._myMagic.magicalGet("currencyRate") != '') {
				totalConverted = total / boundRef.current._myMagic.magicalGet("currencyRate")
			} 

			setTotals({ invoiceSubTotal: total, totalTax: totalTax, totalSavings: totalSavings, totalMargins: totalMargins, totalConverted: totalConverted, currency: currency })
		}
	}

	const downloadCostSavings = () => {
		APP.central.ARInvoice.downloadCostSavingsReport(paramsInvId, false).then((r) => {
			if (r.result != undefined) {
				const file = r.result;
				window.open("/file/download/" + file.storageIdentifier + "/" + file.filename);
			}
		});
	};

	const downloadCostSavingsNew = () => {
		APP.central.ARInvoice.downloadCostSavingsReport(paramsInvId, true).then((r) => {
			if (r.result != undefined) {
				const file = r.result;
				window.open("/file/download/" + file.storageIdentifier + "/" + file.filename);
			}
		});
	};

	const openCostSavingsPreview = () => {
		APP.central.ARInvoice.downloadCostSavingsReport(paramsInvId, false).then((r) => {
			if (r.result != undefined) {
				const file = r.result;
				APP.instance.createModal(<SimplePDFPreview file={file} />, { modal_name: "PDF:" + file.filename });
			}
		});
	};

	const openInvoicePDFPreview = (inv) => {
		if (inv && inv.__type && inv.id) {
			APP.central.File.list(inv.__type, inv.id).then((r) => {
				if (r.result && r.result.length > 0) {
					const file = _.find(r.result, (f) => f.fileCategory == "invoice");
					if (!file) {
						file = r.result[0];
					}
					if (file) {
						APP.instance.createModal(<SimplePDFPreview file={file} />, { modal_name: "PDF:" + file.filename });
					}
				}
			});
		}
	};

	const changedSomething = (diff, line, opts) => {
	
		if (opts && opts.silent) {  
			return;
		}

		//const newInv = _.cloneDeep(inv);
		const arBound = APP.registeredBoundMagics["AR"];

		const thisLineHash = Util.hash(line);
		if (window._lastLineHash == thisLineHash) {
			return;
		}

		window._lastLineHash = thisLineHash;

		APP.central.ARInvoice.prepLine(line).then((r) => {
			const rowIdx = _.findIndex(inv.lines, (l) => { return l.id == r.result.id });
			if (rowIdx >= 0) {
				arBound.getBound(line._keyPath, line._keyPathPos)._ourMagic.replaceTo(r.result);
				if (gridRef.current != null) {
					gridRef.current.refresh();
				}
				updateInvoiceTotals();
			}
		});

	};


	const handleRegisterGrid = (ctx) => {
		gridRef.current = ctx;

		gridRef.current.customKeyUp = gridHotKeyUp;
		gridRef.current.customKeyDown = gridHotKeyDown;
	}

	const setTax = () => {
		//console.log("no");
	};

	let actionItems = undefined;
	if (inv.id > 0 && inv.customer) {
		let entities =
			inv.lines.length > 0
				? _.compact(
					_.uniqBy(
						_.map(inv.lines, (line) => {
							if (line && line.itemAfter && line.itemAfter.site) {
								return { __type: "Site", id: line.itemAfter.site.id };
							}
							return null;
						}),
						"id"
					)
				)
				: [];

		entities.push({ __type: "Customer", id: inv.customer.id });

		actionItems = (
			<>
				<Notes model={inv} entities={entities} types={["ARInvoice"]} />
				<Tasks model={inv} entities={entities} visibility={["billing", "ar_billing"]} />
				<ChangeLogs model={inv} />
			</>
		);
	}

	const openLineMagic = () => {
		const newLine = { itemAfter: { unitPrice: 0, id: 0 }, itemBefore: { unitPrice: 0, id: 0 } };
		let lines = boundRef.current._myMagic.magicalGet("lines");

		let newLineItemCustomer = null;
		if (lines.length > 0 && lines[0].itemAfter.customer != undefined) {
			newLineItemCustomer = lines[0].itemAfter.customer;
		} else {
			newLineItemCustomer = boundRef.current._myMagic.magicalGet("customer");
		}

		if (_.size(newLineItemCustomer) == 0) {
			APP.alert("Assign a customer before creating lines");
			return;
		} else {
			newLine.itemAfter.customer = newLineItemCustomer;
		}

		lines = boundRef.current._myMagic.magicalAppend("lines", newLine);

		const line = BoundMagic.create(lines[lines.length - 1], null, null, boundRef.current._myMagic);
		openLine(line);
	}

	const mandatoryFieldsOkForEachLine = (line)=>{ 
		let requiredFieldsOk = true;
		if(!line.itemAfter.activity ||  ! line.itemAfter.unitPricePer || ! line.itemAfter.taxId	
			|| line.itemAfter.quantity === "" || line.itemAfter.unitPrice === "" || 
			(line.itemAfter.quantity !== 0 && !line.itemAfter.quantity) || 
			(line.itemAfter.unitPrice !== 0 && !line.itemAfter.unitPrice)){  
			requiredFieldsOk = false; 
		}
		return requiredFieldsOk;
	}
	const invoiceLinesRequiredFieldsOk = (to)=>{  
		let requiredFieldsOk = to.lines.every(mandatoryFieldsOkForEachLine)  
		return requiredFieldsOk;
	}

	const datesOkForEachLine = (line)=>{ 
		let dateOkForLine = true;
		var dateReg = /^\d{4}-\d{2}-\d{2}$/;		
		if(line.itemAfter.periodTo && line.itemAfter.periodFrom){
			if(! line.itemAfter.periodFrom.match(dateReg) || ! line.itemAfter.periodTo.match(dateReg)){
				dateOkForLine = false;
			}
			let fromDate = new Date(line.itemAfter.periodFrom)
			let toDate = new Date(line.itemAfter.periodTo)
			if(fromDate > toDate){
				dateOkForLine = false; 
			} 
		}
		else{
			dateOkForLine = false;
		}
		return dateOkForLine;
	}

	const invoiceDatesOk=(to)=>{		
		let datesOk = to.lines.every(datesOkForEachLine)
		return datesOk;
	}

	const checkInvoiceLineFields = (to) =>{ 
		let invoiceLineFieldsOk = true;
		if( to.lines && to.lines.length > 0){ 
			
		    if(!invoiceDatesOk(to)){
			   invoiceLineFieldsOk = false;
			   APP.alert("Failed to Save. Make sure the From and To dates are entered and correct for all the invoice lines.");
		    }
			else if(! invoiceLinesRequiredFieldsOk(to)){
				invoiceLineFieldsOk = false;
				APP.alert("Failed to Save. Make sure all the required fields have values for all the invoice lines.");  
			}
		}
		return invoiceLineFieldsOk;		
	}

	const checkClientRequiredFields = (to)=>{ 
		let clientFieldsOk = true;
		let yearMonthDay = /^\d{4}-\d{2}-\d{2}$/;	
		let yearMonth = /^\d{4}-\d{2}$/;
		if( (!to.customer|| (to.customer && !to.customer.id) || to.status.length == 0 || !to.invoiceTiming || (to.invoiceTiming && to.invoiceTiming.length == 0) 
			|| !to.clientType || !to.billingPeriod || !to.serviceDate)){
				clientFieldsOk = false;
				APP.alert("Failed to Save. Make sure all the required fields have values.");    
		}
		else if(((to.serviceDate &&!to.serviceDate.match(yearMonth))  
			|| (to.date && !to.date.match(yearMonthDay)) 
		    || (to.billingPeriod && !to.billingPeriod.match(yearMonth))))  
		{
			clientFieldsOk = false;
			APP.alert("Failed to Save. Make sure values entered as date have correct format.");  
		}
		return clientFieldsOk; 
	}




	const saveAction = (id, delta, to) => {
		if(checkInvoiceLineFields(to) && checkClientRequiredFields(to)){
			return APP.central.ARInvoice.saveChangesFull(id, delta, to).then((r)=>{ 
				if(r && r.result){
					APP.alert("Save/Update Success.");
				}
			})
		}		
	}

	const openClient = (clientFriendlyId) => {
		APP.instance.createModal("/ui/clients/" + clientFriendlyId, { modal_name: "Client: " + clientFriendlyId });
	}


	const expectedReportTemplate = _.find(related, (thing) => thing != null && thing.reportTemplate != null);

	const purchaseOrderInfo = () => {
    if(!sitesOnInv) {
      return null;
    }
    const pos = _.compact(
			_.map(sitesOnInv, (site) => {
				return site.purchaseOrder;
			})
		);
		if (sitesOnInv && sitesOnInv.length > 0 && pos && pos.length > 0) {
			return <PoRefs pos={pos} />;
		} else {
			return null;
		}
	};

	const PoRefs = (props) => {
		const pos = props.pos;
		const poRefs = _.map(pos, (po, i) => {
			return (
        <Fragment>
          {i != "0" ? <br/> : ""}
				  <i>{po}</i>
        </Fragment>
			);
		});

		return (
			<div className='field'>
				<label>Purchase Orders</label>
          <div>
				    {poRefs}
          </div>
			</div>
		);
	};

	const getInvoiceTiming = (invoiceTiming)=>{
		if(invoiceTiming){
			if(invoiceTiming === 'postBilled'){
				return 'Post Billed'
			  }
			  if(invoiceTiming === 'preBilled'){
				return 'Pre Billed'
			  }
		}
		else{
			return 'Not Set'
		}      
	}

  const getProcessMethod = ()=>{
    if(inv.processMethod){
      if(inv.processMethod === 'manual'){
        return 'Manual'
      }
      if(inv.processMethod === 'automatic'){
        return 'Automatic'
      }
      if(inv.processMethod === 'not_processed'){
        return 'Not Processed'
      }
    }
    else{
      return '-'
    }
  }

	return (
		<div key="edit_invoice" className="edit-ar-invoice basicForm">
			<ActionBar>
				<div style={{ flex: 5 }}></div>
				{actionItems}  
			</ActionBar>

			<div>
				<div style={{color: '#27b2bc', fontWeight:'bold'}}>Invoice Timing: {getInvoiceTiming(invoiceTiming)}</div> 
        <div style={{color: '#27b2bc', fontWeight:'bold'}}>Processed Via: {getProcessMethod()}</div>  

			</div>

			<BoundCommit
				initialValue={inv}
				boundId="AR"
				listenTo={{ ARInvoiceLine: _.debounce(changedSomething, 500) }}
				commit={saveAction}
				toolbar={
					<AccessControl permission="W">
						<ToolbarStuff bound={boundRef ? boundRef.current : null} extra={extra} refreshInvoice={refreshInvoice} receivedByContacts={receivedByContacts} />
					</AccessControl>
				}
				stealBound={setBoundRef}
				permissions={props.permissions}
			>
				<FlexRow>
					{adjustedFromInv ? (
						<Link to={"/ui/arinvoice/" + adjustedFromInv}>
							<h2>Adjusted From Invoice #{adjustedFromInv}</h2>
						</Link>
					) : null}
					<div style={{ display: "flex", gap: "5px" }}>
                    <FileLink showOnlyIfExists={true} fileCategory="invoice" file={hasFile} text="Download Invoice PDF" showPreview={true} previewIcon={"search"} />
						  {/*<Button onClick={() => openInvoicePDFPreview(inv)} className="pdf-preview-button">
							<Icon icon="file-invoice-dollar" size="2x" color="white" />
						</Button>*/}
					</div>
					<div style={{ display: "flex", gap: "5px" }}>
						<Button onClick={downloadCostSavings}>
							<Icon icon="file-pdf" color="white" /> Invoice Details
						</Button>
						<Button onClick={downloadCostSavingsNew}>
							<Icon icon="repeat" size="2x" color="white" title="Generate New Invoice Details"/>
						</Button>
						<Button onClick={openCostSavingsPreview} className="pdf-preview-button" title="Preview Cost Savings">
							<Icon icon="file-invoice-dollar" size="2x" color="white" />
						</Button>
					</div>
					{/*<div className="block blueish" onClick={downloadCostSavings} ><Icon icon="file-pdf" color="white"/> Cost Savings</div>*/}
				</FlexRow> 

				
				<FlexRow />			
				<InputAutoComplete 
					className="client-selector" 
					what="customer"
					field="customer"   
					name="Client" 
				    central={APP.central.Customer.autocomplete} 
					disabled={arPosted ? true : false} 
					forAR={true}
					store={"object"} 
					invReqField={true}>   
			            {(customer) => <div className="customer_option" style={{marginBottom:"8px"}}><span>({customer.friendlyId})</span> - {customer.displayValue}</div>}   
						
		        </InputAutoComplete>    




				<LookupInputSelect what="lookup:Invoice Status" field="status" name="Invoice Status" showEmpty={true} disabled={arPosted ? true : false} invReqField={true} />
				<LookupInputSelect what="lookup:Billing Time" name="Invoice Timing" field="invoiceTiming" disabled={arPosted ? true : false} invReqField={true} />
				<LookupInputSelect what="clientType" field="clientType" name="Client Type" store="object" disabled={arPosted ? true : false} invReqField={true}/> 

				<CompensationSelector name="Compensation Type" showEmpty={false} disabled={arPosted ? true : false} invReqField={true}/> 
				{showBlendedCrap ? (
					<div className="field" style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
						<InputCheckbox name="Show Baseline" field="reportTemplateOptions.showBaseline" />
						<InputCheckbox name="Blended Breakdown" field="reportTemplateOptions.blendedDetails" />
					</div>
				) : null} 
				<MagicalState
					to="reportTemplate"
					setter={(template) => {
						if (template != null) {
							setCurrentReportTemplate(template.id); 
						}
					}}
				/>
				
				<ReportTemplateLookupSelector field="reportTemplate" label="Report Template" disabled={arPosted ? true : false} invReqField={true}>
					{currentReportTemplate != null && expectedReportTemplate != null && expectedReportTemplate.reportTemplate.id != currentReportTemplate ? (
						<label className="field error">
							<field>
								<em>Client report template mismatch</em>
							</field>
						</label>
					) : null}
				</ReportTemplateLookupSelector> 
				
				<StaffSelect what="staff" field="invoicePreparer" disabled={arPosted ? true : false} name="Invoice Preparer"/> 
				<SimpleMonthPicker field="billingPeriod" label="Billing Period" invReqField={true}/>		
				
				
				<DatePicker field="date" name="Invoice Date" disabled={arPosted ? true : false}  monthsShown={2} showPreviousMonths={true} invoiceDate={true}/> 
				<ManualProcessEditors /> {/** rendering service date **/}
				<XeroLink type="ARInvoice" /> 

                 {purchaseOrderInfo()}

				{receivedByContacts.length > 0 ? <InvoiceClientContactDetails receivedByContacts={receivedByContacts} /> : null }
				
				{/* <FlexBreak /> */}

				{/** manual ar chunks **/}
				
				
				
				
				<FlexRow>
					<h2>Line Items</h2>

					{/*<CombinedInvoices invoices={extra && extra.combinedInvoices ? extra.combinedInvoices : null} />*/}
					<MissingServices 
            missingServices={extra && extra.missingServices ? extra.missingServices : null} 
            servicesBilledInBillingPeriod={extra && extra.servicesBilledInBillingPeriod ? extra.servicesBilledInBillingPeriod : null} 
            theClient={inv && inv.customer ? inv.customer : null}
            sites={sitesOnInv}
          />

				</FlexRow>
				<FlexBreak />
				<div className="lines-container">
					<div className="compact">
						{!inv.hasOwnProperty("status") || (inv && inv.xeroUUID) ? null :
							<AccessControl permission="W">
								<Icon title="Edit" icon="pencil" onClick={() => setEditingLines(!editingLines)} />
							</AccessControl> 
						}
						{editingLines ? (
							<ARLineGrid
								addToRelated={(r) => addToRelated(r)}
								inv={inv}
								columnsToHide={columnsToHide}
								handleCellAction={handleCellAction}
								handleRegisterGrid={handleRegisterGrid}
								related={related}
								openLine={openLine}
								refresh={refresh}
								setTax={setTax}
								order={orderBy}
								orderDirection={orderByDirection}
								extra={extra}
							/>
						) : (
							<ARLineTabular
								related={related}
								columnsToHide={columnsToHide}
								inv={inv}
								order={orderBy}
								orderDirection={orderByDirection}
								voidAvailable={voidAvailable}
								extra={extra}
							/>
						)}
					</div>
				</div>

				<FlexRow>
					<InputText field="internalNotes" inputType="textarea" name="Notes" className="" />
					{totals.currency != "CAD" ? (
									<>Prices shown are in {totals.currency}</>
								) : null}
					<table className="balancing">
						<thead>
							<tr>
								<td></td>
								<th>Subtotal</th>
								<th>Tax</th>
								<th>Total Invoice</th>
								{totals.currency != "CAD" ? (
									<th>Total CAD</th>
								) : null}
							</tr>
						</thead>
						<tbody>
							<tr>
								<td></td>
								<td>
									<NiceCurrency>{totals.invoiceSubTotal}</NiceCurrency>
								</td>
								<td>
									<NiceCurrency>{totals.totalTax}</NiceCurrency>
								</td>
								<td>
									<NiceCurrency>{totals.invoiceSubTotal + totals.totalTax}</NiceCurrency>
								</td>
								{totals.currency != "CAD" ? (
									<td><NiceCurrency>{totals.totalConverted}</NiceCurrency></td>
								) : null}
							</tr>
							{showSavings ? (
								<tr>
									<th>Savings</th>
									<td>
										<NiceCurrency>{totals.totalSavings}</NiceCurrency>
									</td>
								</tr>
							) : null}
						</tbody>
					</table>
				</FlexRow>
			</BoundCommit>

			<div className="clearStuff"></div>

			<pre>{raw}</pre>
		</div>
	);
}

const LinkToAP = (props) => {
	const bound = useContext(DataContext);
	const xeroLink = bound.magicalGet("xeroUUID");

	if (Number.isInteger(props.id) && props.id != 0) {
		return <LinkButton target={"/ui/apinvoice/" + props.id} title={"AP " + props.id}>
			<NiceCurrency>{props.row.totalMargin}</NiceCurrency>
		</LinkButton>;
	} else if (props.row != null && props.row.totalMargin != null) {
		return <div title="No AP Link Available"><NiceCurrency>{props.row.totalMargin}</NiceCurrency></div>;
	} else if (props.id == null) {
		return <span>-</span>;
	} else if (props.id != null) {
		return <span>?</span>;
	} else {
		return null;
	}
}
export const DatePickerWithPeriodToUpdater = (props) => {  
	const magic = useContext(DataContext);
	useEffect(() => {
		magic.on("periodFrom", (a, b, c, d) => {
			if (d == "update") {
				if (magic.magicalGet("periodFrom",null) != null){
					if (magic.magicalGet("unitPricePer") == "month" || magic.magicalGet("unitPricePer") == "surcharge") {
						magic.magicalSet("periodTo", moment(magic.magicalGet("periodFrom")).endOf("month").format("YYYY-MM-DD"));
					} else {
						magic.magicalSet("periodTo", moment(magic.magicalGet("periodFrom")).format("YYYY-MM-DD"));
					}
				}
			}
		})
	}, [magic])
	return <DatePicker field="periodFrom" monthsShown={2} showPreviousMonths={true} invReqField={props.invReqField}/>
}

const ARLineGrid = ({ inv, columnsToHide = {}, handleCellAction, handleRegisterGrid, related, refresh, setTax, openLine, order = ["itemAfter.service", "itemAfter.id", "id"], orderDirection = ["asc", "desc", "asc"], addToRelated, extra = {} }) => {
	const showingBefore = _.keys(columnsToHide).indexOf('Shared Savings') >= 0
	const doOrders = [ (row)=>{ return row.id > 0 ? row.id : (1000000 + (-1*row.id)) } ];

	const showAdj = (extra.adjustmentData && extra.adjustmentData.length > 0) ? true : false;

	return <Grid registerGrid={handleRegisterGrid} boundData="lines" orderBy={doOrders} orderByDirection={orderDirection} key="ar_lines" gridIdentifier="ar_lines" onCellAction={handleCellAction} disableHoleActions={true} appendable={{ text: "add line", add: handleCellAction, column: 0 }}>
		<Grid boundData="itemAfter">
			{showAdj ?
				<GridItem title="Adjustment">
					<AdjustmentInfo extra={extra} />
				</GridItem>
				: null}
			<GridItem title="Site" width="85" justify="left">
				<ServiceInfo addToRelated={addToRelated} related={related} displayField="site" />
			</GridItem>
			<GridItem title="Address" width="85" justify="left">
				<ServiceInfo addToRelated={addToRelated} related={related} displayField="siteAddress" />
			</GridItem>
			<GridItem title="Service Period From">
				{/*<DatePicker field="periodFrom" monthsShown={2} showPreviousMonths={true} update={doThing}/>*/}
				<DatePickerWithPeriodToUpdater invReqField={true}/> 
			</GridItem>
			<GridItem title="Service Period To">
				<DatePicker field="periodTo" monthsShown={2} showPreviousMonths={true} invReqField={true}/>
			</GridItem>
			<GridItem title="Description" width="125" justify="left">
				<ServiceInfo addToRelated={addToRelated} related={related} />
			</GridItem>
			<GridItem title="Activity">
				<ActivityThing show="WSC" field="activity" edit={true} invReqField={true}/>
			</GridItem>
			<GridItem title="Date">
				<ServiceDates />
			</GridItem>
			<GridItem title="Quantity" width={75}>				
				<InputDecimal field="quantity" name="Quantity" places={0} maxPlaces={6} parent="invoiceLine" invReqField={true}/>
			</GridItem>
			<GridItem title="Unit Price" width={75}>				
				<InputDecimal field="unitPrice" name="Unit Price" places={2} maxPlaces={6} parent="invoiceLine" invReqField={true}/>
			</GridItem>
			<GridItem title="Per" width={75}>
				<LookupInputSelect what="lookup" field="unitPricePer" invReqField={true}/>
			</GridItem>
		</Grid>
		{(showingBefore) ? <Grid boundData="itemBefore">
			<GridItem title="Quantity (Before)" width={75}>
				<InputDecimal field="quantity" name="Quantity" places={0} maxPlaces={6} />
			</GridItem>
			<GridItem title="Unit Price (Before)" width={75}>
				<InputDecimal field="unitPrice" name="Unit Price" places={2} maxPlaces={6} />
			</GridItem>
			<GridItem title="Per (Before)" width={75}><LookupInputSelect what="lookup" field="unitPricePer" />
			</GridItem>
		</Grid> : null}
		<Grid boundData="itemAfter">
			<GridItem title="Reference">
				<InputText field="description" name="Reference" />
			</GridItem>
			<GridItem title="Total"> 
				<TotalsFormula field="extendedPrice" refresh={refresh} /> 
			</GridItem>
			<GridItem title="?">
				-
			</GridItem>
			<GridItem title="Taxes">
				<LookupInputSelect what="taxes" field="taxId" name="Tax" emptyIsNull={true} /*onChange={setTax}*/ parent="invoiceLine" invReqField={true} /> 
			</GridItem>
			<GridItem title="Total w/Tax"> 
				<TotalsFormula field="extendedPrice" applyTax={true} refresh={refresh} />
			</GridItem>
			<GridItem title="AP">
				<LinkToAP id='a' />
			</GridItem>
		</Grid>
		{columnsToHide["Cost +"] == true ?
			null :
			<GridItem title="Savings">
				<TotalsFormula field="totalSavings" applyTax={false} refresh={refresh} />
			</GridItem>
		}
		<GridItem title="Last Change">
			<Person field="lastModifiedBy" />
		</GridItem>

		<GridItem title="Actions">
			<RowActions action={(event, rowDetails, gridContext, action) => handleCellAction(event, rowDetails, gridContext, action)} openLine={(boundTo) => openLine(boundTo, addToRelated)} />
		</GridItem>
	</Grid>
}

const ARLineTabular = ({ related, inv, columnsToHide = {}, order = ["itemAfter.service", "itemAfter.id", "id"], orderDirection = ["asc", "desc", "asc"], voidAvailable = false, extra = {} }) => {
	const boundMagic = useContext(DataContext);
	const [items, setItems] = useState([]);
	const [selection, setSelection] = useState([]);
	const [adjusting, setAdjusting] = useState(false);
	const [adjustedData, setAdjustedData] = useState(extra.adjustmentData ? extra.adjustmentData : []);

	useEffect(() => {
		setItems(
			_.flatten(
				_.map(_.orderBy(inv.lines, order, orderDirection), (line) => {
					const item = { ...line.itemAfter };
					item.paymentAdjustment = line.paymentAdjustment;
					if (item.paymentAdjustment == undefined) {
						item.paymentAdjustment = 0;
					}
					return item;
				})
			)
		);
	}, [inv]);

	useEffect(() => {
		setAdjustedData(extra.adjustmentData ? extra.adjustmentData : []);
	}, [extra]);

	const getTotalWithTax = (row) => {
		return calculateFinalTotal(row.extendedPrice, row.paymentAdjustment, true, row.taxId != undefined ? row.taxId.rate : 0, true);
	}

	const getSavings = (row) => {
		let totalSavings = 0;
		const rowLine = _.find(boundMagic.magicalGet("lines", []), (line) => { return line.itemAfter.id == row.id });

		if (rowLine != undefined) {
			totalSavings = rowLine.totalSavings;
		}
		return totalSavings;
	}

	const adjustSelected = () => {
		const ids = selection.map((item) => item.id);
		const total = _.sum(
			_.map(selection, (item) => {
				return item.unitPrice * item.quantity * -1;
			})
		);
		APP.confirm("This will create an adjustment for " + Util.currency(total), (res) => {
			if (res == true) {

				APP.central.ARInvoice.makeAdjustment(inv.id, ids).then((r) => {
					APP.redirect("/ui/arinvoice/" + r.result.id);
				});

				setSelection([]);
				setAdjusting(false); 
			}
		});
	};

	const cancelAdjustment = () => { 
		setSelection([]);
		setAdjusting(false);
	}

	return (
		<Fragment>
			<Tabular data={items}>
				{adjustedData.length > 0 ? (
					<TabularColumn
						data={(row) => {
							const ret = [];
							_.forEach(adjustedData, (adjData) => {
								if (adjData.modified_line == _.toString(row.lineItemTypeRelatedId)) {
									//ret.push(adjData.new_invoice);
									ret.push(adjData);
								}
							});

							const display = _.map(ret, (r) => {
								return (
									<Link to={"/ui/arinvoice/" + r.new_invoice}>
										{r.extended_price ? (
											<span style={{ color: "red", fontWeight: "bold" }}>{r.extended_price}</span>
										) : (
											<Icon title={"Go To Adjustment Invoice #" + r.new_invoice} icon="exclamation" size="2x" color="red" />
										)}
									</Link>
								);
							});

							return <div className="adjustedAlert">{display}</div>;
						}}
						title="Adjustment"
					/>
				) : null}

				{adjusting ? (
					<TabularColumn
						data={(row) => (
							<Fragment>
								<Selection selection={selection} data={items} setter={setSelection} item={row} label={(row) => ""} />
							</Fragment>
						)}
						title="Adjust?"
					/>
				) : null}
				<TabularColumn
					data={(row) => (
						<Fragment>
							<ServiceInfo related={related} displayField="site" row={row} />
						</Fragment>
					)}
					title="Site"
				/>
				<TabularColumn
					data={(row) => (
						<Fragment>
							<ServiceInfo related={related} displayField="siteAddress" row={row} />
						</Fragment>
					)}
					title="Address"
				/>
				<TabularColumn data={(row) => <ServiceInfo related={related} row={row} />} title="Description" />
				<TabularColumn title="Service Period From" data={(row) => <NiceDate>{row.periodFrom}</NiceDate>} />
				<TabularColumn title="Service Period To" data={(row) => <NiceDate>{row.periodTo}</NiceDate>} />
				<TabularColumn data={(row) => (row.activity != undefined ? row.activity : null)} title="Activity" />
				<TabularColumn data={(row) => <ServiceDates from={row.periodFrom} to={row.periodTo} />} title="Date" />
				<TabularColumn
					data={(row) => (
						<NiceNumber places={0} maxPlaces={6}>
							{row.quantity}
						</NiceNumber>
					)}
					title="Quantity"
				/>
				<TabularColumn
					data={(row) => (
						<NiceNumber places={2} maxPlaces={6}>
							{row.unitPrice}
						</NiceNumber>
					)}
					title="Unit Price"
				/>
				<TabularColumn data={(row) => row.unitPricePer} title="Per" />
				<TabularColumn data={(row) => row.description} title="Reference" />
				<TabularColumn data={(row) => <NiceCurrency>{row.extendedPrice}</NiceCurrency>} title="Total" />
				<TabularColumn data={(row) => (row.taxId != undefined ? row.taxId.name : "")} title="Taxes" />
				<TabularColumn data={(row) => <NiceCurrency>{getTotalWithTax(row)}</NiceCurrency>} title="Total w/Tax" />
				{columnsToHide["Cost +"] == true ? null : <TabularColumn data={(row) => <NiceCurrency>{getSavings(row)}</NiceCurrency>} title="Savings" />}
				{/*<TabularColumn data={(row) => <LinkToAP row={row} id={row.apLink} />} title="Margin" />*/}
			</Tabular>
			<br />
			<AccessControl permission="W">
				<div className="adjustmentButtons">
					{!adjusting && voidAvailable ? <Button onClick={() => setAdjusting(true)}>Start Adjustment</Button> : null}
					{adjusting && voidAvailable ? <Button onClick={() => cancelAdjustment()}>Cancel Adjustment</Button> : null}
					{selection.length > 0 ? <Button onClick={() => adjustSelected()}>Create Adjustment for {selection.length} selection(s)</Button> : null}
				</div>
			</AccessControl>
		</Fragment>
	);
}

const RowActions = ({ action, openLine }) => {
	const bound = useContext(DataContext);

	const openLineEdit = (line) => {
		openLine(line);
	};

	return <div className="ar-line-row-actions">
		<EditLink openLine={(boundTo) => openLineEdit(boundTo)} />
		<span className="copy" onClick={(e) => action(e, { row: bound.to }, null, "copy")}><Icon icon="copy" size="2x" title="Copy (ctrl + shift + X)" /></span>
		<span className="new-line" onClick={(e) => action(e, { row: bound.to }, null, "new")}><Icon icon="plus-square" size="2x" title="New Line (enter)" /></span>
		<span className="clear" onClick={(e) => action(e, { row: bound.to }, null, "clear")}><Icon icon="empty-set" size="2x" title="Clear (ctrl + shift + backspace)" /></span>
		<span className="delete" onClick={(e) => action(e, { row: bound.to }, null, "delete")}><Icon icon="trash" size="2x" title="Delete (ctrl + shift + D)" /></span>
	</div>
}

const LinkAP = ({ tabularRow = undefined }) => {
	const boundMagic = useContext(DataContext);
	const [source, setSource] = useState(null);

	useEffect(() => {
		if (tabularRow != undefined) {
			const relatedLine = _.find(boundMagic.magicalGet("lines", []), (row) => { return row.id == tabularRow.lineItemTypeRelatedId });
			if (relatedLine != undefined) {
				setSource(relatedLine.source);
			}
		} else {
			setSource(boundMagic.magicalGet("source", null));
		}
	}, [boundMagic]);

	if (source == null) {
		return null;
	}

	const navigateToAP = (source) => {
		if (source == null) {
			APP.instance.openConfirmDialog("No Matching AP");
		} else {
			APP.central.ARInvoice.findAPLineBySource(source).then((r) => {
				if (r.result != undefined) {
					APP.instance.createModal(r.result, { modal_name: "AP Invoice" });
				} else {
					APP.instance.openConfirmDialog("No Matching AP");
				}
			});
		}
	}

	return <Link to="#" onClick={() => navigateToAP(source)}>AP</Link>;
}

const MissingServices = ({ missingServices, servicesBilledInBillingPeriod, theClient, sites }) => {

  const [client, clientDS] = useData("customer", theClient && theClient.id ? theClient.id : 0);
  const [invoiceType, setInvoiceType] = useState(null);

  useEffect(() => {
    if(client && client.invoiceType){
      setInvoiceType(client.invoiceType);
    }
  }, [clientDS.loading]);

	if (missingServices == null || missingServices.length == 0) {
		return null;
	}

  const servicesBilledInBillingPeriodBySite = _.groupBy(servicesBilledInBillingPeriod, 'site_id');

  let tempMissingServicesBySite = _.map(_.groupBy(missingServices, 'site'), (services, site) => { 
    let siteServGroup = {};

    const billedSite = servicesBilledInBillingPeriodBySite[site] ? servicesBilledInBillingPeriodBySite[site] : null;
    const vendorNames = _.uniq(_.map(services, 'vendor_name'));
    const vendorIds = _.uniq(_.map(services, 'vendor_id'));
    let arIds = [];
    let arStatuses = [];

    if(billedSite) {
      _.forEach(billedSite, (billedService) => {
        //if billedService.ar_id is not in arIds, add it and add the ar_status to arStatuses
        if(!arIds.includes(billedService.ar_id)) {
          arIds.push(billedService.ar_id);
          arStatuses.push(billedService.ar_status);
        }
      });
    }

    siteServGroup.site = site;
    siteServGroup.siteName = services[0].site_name;
    siteServGroup.arIds = arIds;
    siteServGroup.arStatus = arStatuses;
    siteServGroup.missingServices = services.length;
    siteServGroup.billedServices = billedSite ? billedSite.length : 0;
    siteServGroup.vendorIds = vendorIds;
    siteServGroup.vendorNames = vendorNames;
    siteServGroup.services = services;
    
    return siteServGroup; 
  });

  /* this is now done on backend to match the post warnings
  if (invoiceType == "separate" && sites && sites.length > 0) {
    const siteIds = _.map(sites, 'id');
    const something = _.filter(tempMissingServicesBySite, (missingService) => {
      return _.includes(siteIds, _.toNumber(missingService.site));
    });
    tempMissingServicesBySite = something;
  }
  */

  const missingServicesBySite = tempMissingServicesBySite;
  let count = 0;
  _.forEach(missingServicesBySite, (missingService) => {
    _.forEach(missingService.services, (service) => {
      count++;
    });
  });

  if (missingServicesBySite == null || missingServicesBySite.length == 0) {
		return null;
	}


  const openServices = (services) => {

    const data = _.map(services, (service) => {
      return {
        id: service.id,
        friendlyId: service.friendly_id,
        shortDescription: service.description,
        historical: service.historical,
      }
    });

    APP.instance.createModal(
      <EditServicesWithTabs data={data} serviceId={data[0].id} />,
        { modal_name: "Services" }
    );
  }
  
	return (
    <HoverElement
      content={
        <>
          <strong>Billing Period Missing Services:</strong> {" " + count}
        </>
      }
    >
      <div className="combinedInvoiceHover">
        <h4>Related Invoices</h4>
        <Tabular data={missingServicesBySite}>

          <TabularColumn title="Site" data={(row) => <Link to={"/ui/sites/detail/" + row.site}>{row.siteName}</Link>} />

          <TabularColumn
            title="Billed Services"
            data={(row) => (
              <Link to="#" onClick={() => openServices(row.services)}>
                {row.billedServices + "/" + (row.billedServices + row.missingServices)}
              </Link>
            )}
            style={{ textAlign: "center" }}
          />

          <TabularColumn
            title="Status"
            data={(row) =>
              _.map(row.arStatus, (status) => {
                return <div>{status}</div>;
              })
            }
          />

          <TabularColumn
            title="AR"
            data={(row) =>
              _.map(row.arIds, (id) => {
                return (
                  <div>
                    <LinkButton target={"/ui/arinvoice/" + id}>View {id}</LinkButton>
                  </div>
                );
              })
            }
          />

          <TabularColumn
            title="Vendor"
            data={(row) =>
              _.map(row.vendorIds, (id, i) => {
                return (
                  <div>
                    <LinkButton target={"/ui/vendors/edit/" + id}>{row.vendorNames[i]}</LinkButton>
                  </div>
                );
              })
            }
          />

        </Tabular>
      </div>
    </HoverElement>
  );
}

const CombinedInvoices = ({ invoices }) => {

	if (invoices == null || invoices.length == 0) {
		return null;
	}
	const details = _.map(_.groupBy(invoices, 'ar_status'), (a) => { return a.length + " " + a[0].ar_status }).join(', ')
	return <HoverElement content={<><strong>Combined Invoices:</strong> {details}</>}>
		<div className="combinedInvoiceHover">
			<h4>Related Invoices</h4>
			<Tabular data={invoices}>
				<TabularColumn title="Site" data={(row) => <Link to={"/ui/sites/detail/" + row.ar_id}>{row.name}</Link>} />
				<TabularColumn title="Status" data={(row) => row.ar_status} />
				<TabularColumn title="AR" data={(row) => <LinkButton target={"/ui/arinvoice/" + row.ar_id}>View AR</LinkButton>} />
			</Tabular>
		</div>
	</HoverElement>
}

const ManualProcessEditors = () => {
	const boundMagic = useContext(DataContext);

	if (boundMagic.magicalGet("id") != "") {
		let useOverrides = boundMagic.magicalGet("lines").length > 0 && boundMagic.magicalGet("lines")[0].source != null && boundMagic.magicalGet("lines")[0].source.indexOf("AR:") == 0;

		if (!useOverrides) {
			useOverrides = boundMagic.magicalGet("lines").length == 0 || boundMagic.magicalGet("lines")[0].source == undefined;
		}

		if (useOverrides) {
			return <>
			    <SimpleMonthPicker field="serviceDate" label="Service Date"  invReqField={true}/> 
				{/* <DatePicker field="serviceDate" name="Service Date" required={true}/> */}
				{/* <DatePicker field="serviceDate" name="Service Date"  format="YYYY-MM" invReqField={true}/>   */}
				<FlexBreak /> 
			</>;
		}
	}

	return null;
}

export const ActivityThing = ({ field = "activity", show = "WSC", edit = false, invReqField = false }) => {  
	const bound = useContext(DataContext);
	let title = "";
	let descriptor = bound.magicalGet("activity");

	if (show == "vendor" && bound.magicalGet("vendorActivity.activity")) {
		title = "WSC " + descriptor;
		descriptor = bound.magicalGet("vendorActivity.activity");
	} else {
		if (bound.magicalGet("vendorActivity.activity")) {
			title = "Vendor " + bound.magicalGet("vendorActivity.activity");
		}
	}

	if (edit) {
		return <LookupInputSelect what="lookup" field={field} name="Activity" invReqField={invReqField}/>  
	}

	if (descriptor != null && descriptor > "") {
		return <span title={title}>{descriptor}</span>;
	}

	return <span title={""} className="none"></span>;
}

export const AdjustmentInfo = ({ extra = {} }) => {
	const bound = useContext(DataContext);
	const adjustedData = extra.adjustmentData ? extra.adjustmentData : [];

	const ret = [];
	_.forEach(adjustedData, (adjData) => {
		if (adjData.modified_line == _.toString(bound.to.lineItemTypeRelatedId)) {
			ret.push(adjData);
		}
	});
	const display = _.map(ret, (r) => {
		return (
			<Link to={"/ui/arinvoice/" + r.new_invoice}>
				{r.extended_price ? (
					<span style={{ color: "red", fontWeight: "bold" }}>{r.extended_price}</span>
				) : (
					<Icon title={"Go To Adjustment Invoice #" + r.new_invoice} icon="exclamation" size="2x" color="red" />
				)}
			</Link>
		);
	});

	return <div className="adjustedAlert">{display}</div>;
}

export const OutboundMessages = ({ extra = {} }) => {
	const outboundMessages = extra.outboundMessages ? extra.outboundMessages : [];
	let count = 0;
	_.forEach(outboundMessages, (om) => {
		if (om.status != "sent") {
			count++; 
			count++; 
		}
	});
	const display = count + " Email(s) queued to be sent";

	return (
		<Fragment>
			{count > 0 ? (
				<HoverElement content={display}>
					<Tabular data={outboundMessages}>
						<TabularColumn title="Status" data={(row) => row.status} />
						<TabularColumn title="Date Created" data={(row) => row.date_created} />
						<TabularColumn title="Date Sent" data={(row) => row.date_sent} />
					</Tabular>
				</HoverElement>
			) : null}
		</Fragment>
	);
};


const InvoiceClientContactDetails = ({ receivedByContacts, customerId, invoiceId }) => {
	useEffect(() => {
		if (invoiceId != null && invoiceId != '') {
			APP.central.Site.fetchInvoiceSiteClientContacts(invoiceId).then((r) => {
			receivedByContacts = r.result;
			})
		}
	}, [customerId, invoiceId])
	const [client, clientDS] = useData("customer", customerId ? customerId : 0);
	return <HoverElement content={<div className="field"><label>{client.reportType == 'combined' ? "(Combined Invoice) " : null}To Be Received By: {receivedByContacts.length}</label></div>}>
		<div className="email-hover-content">
			<Tabular data={receivedByContacts}>
				<TabularColumn title="Name" data={(row) => row.first_name.trim() + " " + row.last_name.trim()} />
				<TabularColumn title="Email" data={(row) => row.email} />
			</Tabular>
		</div>
	</HoverElement>
}
